$color_1: #333448;
$color_2: #009ce7;
$color_3: #2e37a4;
$color_4: #000;
$color_5: #757575;
$color_6: #009efb;
$color_7: #9e9e9e;
$color_8: #aaa;
$color_9: #565656;
$color_10: #55ce63;
$color_11: #f62d51;
$color_12: #ffbc34;
$color_13: #333;
$color_14: #00d3c7;
$color_15: #7460ee;
$color_16: #fff;
$color_17: #2e37a4;
$color_18: rgba(46, 55, 164, 0.3);
$color_19: rgba(46, 55, 164, 0.5);
$color_20: #888;
$color_21: rgba(51, 53, 72, 0.75);
$color_22: #ff0000;
$color_23: rgba(51, 52, 72, 0.5);
$color_24: #00d3c7;
$color_25: #a0a0a0;
$color_26: #18aefa;
$color_27: rgba(51, 52, 72, 0.2);
$color_28: #ff3667;
$color_29: #d5d7ed;
$color_30: rgba(51, 53, 72, 0.5);
$color_31: rgb(51 52 72 / 20%);
$color_32: #333548;
$color_33: #fe0000;
$color_34: #676767;
$color_35: #ffffff;
$color_36: rgba(255, 255, 255, 0.5);
$color_37: #171717;
$color_38: #838383;
$color_39: #399e8d;
$color_40: #000000;
$color_41: rgba(51, 52, 72, 0.3);
$color_42: #35446f;
$color_43: rgba(17, 17, 17, 0.32);
$color_44: #fff;
$color_45: #808184;
$color_46: #636e8e;
$color_47: #6586bf;
$color_48: #0dbf0a;
$color_49: #fe3f51;
$color_50: #ffb627;
$color_51: #234ce3;
$color_52: transparent;
$color_53: #bbb;
$color_54: #777;
$color_55: #797979;
$color_56: #515365;
$color_57: #888da8;
$color_58: #616161;
$color_59: #bdbdbd;
$color_60: #989c9e;
$color_61: #ccc;
$color_62: #f00;
$color_63: #1b2559;
$color_64: #ffffff;
$color_65: #2196f3;
$color_66: #26af48;
$color_67: #ff9b01;
$color_68: #008cff;
$color_69: #8f13fd;
$color_70: #ff01a2;
$color_71: #818181;
$color_72: #3d5ee1;
$color_73: #8c8c8c;
$color_74: #ffd200;
$color_75: #999;
$color_76: rgba(51, 52, 72, 0.75);
$color_77: #282628;
$color_78: #909090;
$color_79: #ababab;
$color_80: #656565;
$color_81: #f06060;
$color_82: #4f5155;
$color_83: #a0a0a2;
$color_84: #6c757d;
$color_85: #c4c4c4;
$color_86: #f09e9e;
$color_87: #858585;
$color_88: #ec1361;
$color_89: #272b41;
$color_90: #8f9bba;
$color_91: rgb(51 53 72 / 50%);
$color_92: #131523;
$color_93: #ff0000;
$font-family_1: "Poppins", sans-serif;
$font-family_2: "FontAwesome";
$font-family_3: "Rubik", sans-serif;
$font-family_4: inherit;
$font-family_5: "Font Awesome 5 Free";
$font-family_6: "Font Awesome 6 Free";
$font-family_7: "Font Awesome 5 Free";
$background-color_1: #fff;
$background-color_2: #f6f6f6;
$background-color_3: #009efb;
$background-color_4: #55ce63;
$background-color_5: #ffbc34;
$background-color_6: #f62d51;
$background-color_7: #2e37a4;
$background-color_8: #00d3c7;
$background-color_9: #028ee1;
$background-color_10: #e6294b;
$background-color_11: #2e37a4;
$background-color_12: rgba(0, 0, 0, 0.2);
$background-color_13: #eee;
$background-color_14: rgba(255, 255, 255, 0.1);
$background-color_15: #ccc;
$background-color_16: #faa937;
$background-color_17: transparent;
$background-color_18: #e6e6e8;
$background-color_19: #18aefa;
$background-color_20: #3d5ee1;
$background-color_21: #000;
$background-color_22: #ffbc35;
$background-color_23: #333448;
$background-color_24: #ffe5e6;
$background-color_25: #fe0000;
$background-color_26: #aaa;
$background-color_27: #e4eeff;
$background-color_28: #234ce3;
$background-color_29: #fc6075;
$background-color_30: #ddd;
$background-color_31: #fafafa;
$background-color_32: rgba(0, 0, 0, 0.6);
$background-color_33: #f5f5f5;
$background-color_34: rgba(17, 148, 247, 0.12);
$background-color_35: rgba(15, 183, 107, 0.12);
$background-color_36: rgb(255 218 218 / 49%);
$background-color_37: #474d52;
$background-color_38: #fef5e4;
$background-color_39: #e5f3fe;
$background-color_40: #f3e7fd;
$background-color_41: #ffe5f6;
$background-color_42: #b2b2b2;
$background-color_43: #ffffcc;
$background-color_44: #f4f4f4;
$background-color_45: #f06060;
$background-color_46: rgba(0, 0, 0, 0.5);
$background-color_47: rgba(0, 0, 0, 0.66);
$background-color_48: #c4c4c4;
$background-color_49: rgba(0, 0, 0, 0.25);
$border-color_1: #2e37a4;
$border-color_2: #dee2e6;
$border-color_3: transparent;
$border-color_4: #009efb;
$border-color_5: #00d3c7;
$border-color_6: #18aefa;
$border-color_7: #2e37a4;
$border-color_8: #ccc transparent transparent;
$border-color_9: transparent transparent #ccc;
$border-color_10: #f3f3f3;
$border-color_11: transparent transparent #ffffff #ffffff;
$border-color_12: #eaeaea;
$border-color_13: transparent transparent transparent #009efb;
$border-top-color_1: #2e37a4;
$border-right-color_1: #fff;
$border-right-color_2: #009efb;
$border-right-color_3: #00d3c7;
$border-bottom-color_1: #ddd;
$border-bottom-color_2: #009efb;
$border-bottom-color_3: #2e37a4;
$border-left-color_1: transparent;
$border-left-color_2: #009efb;

/*
Author       : Dreamguys
Template Name: PreClinic - Medical and Hospital Admin Template
Version      : 1.0
*/
/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Header
6. Sidebar
7. Content
8. Login
9. Dashboard
10. Activity
11. Select2
12. Nav tabs
13. Holidays
14. Edit Profile
15. Chat
16. Focus Label
17. Leave
18. Employee
19. Events
20. Profile
21. Notifications
22. Roles & Permissions
23. Chat Right Sidebar
24. Invoice
25. Doctors
26. Add Doctor
27. Payslip
28. Attendance
29. Inbox
30. Mail View
31. Blog
32. Blog View
33. UI Kit
34. Error
35. Lock Screen
36. Voice call
37. Video Call
38. Incoming call
39. Notification settings
40. Chat Sidebar
41. Gallery
42. Settings
43. Responsive

========================================*/
/*-----------------
	1. General
-----------------------*/
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600";
/*-----------------
	2. Table
-----------------------*/
/*-----------------
	3. Helper Class
-----------------------*/
/*-----------------
	4. Bootstrap Classes
-----------------------*/
/*-----------------
	5. Header
-----------------------*/
/*-----------------
	6. Sidebar
-----------------------*/
/*-----------------
	7. Content
-----------------------*/
/*-----------------
	12. Login
-----------------------*/
/*-----------------
	14. Login
-----------------------*/
/*-----------------
	9. Dashboard
-----------------------*/
@keyframes pulse {
  100% {
    transform: scale(2.5);
  }
}
/*-----------------
	10. Activity
-----------------------*/
/*-----------------
	11. Select2
-----------------------*/
/*-----------------
	12. Nav tabs
-----------------------*/
/*-----------------
	13. Holidays
-----------------------*/
/*-----------------
	14. Edit Profile
-----------------------*/
/*-----------------
	15. Chat
-----------------------*/
/*-----------------
	10. Join Call
-----------------------*/
/*-----------------
	Meeting Call
-----------------------*/
@keyframes mymove {
  from {
    color: #0074e0;
  }
  to {
    color: transparent;
  }
}
/*-----------------
	Email
-----------------------*/
/*-----------------
	16. Focus Label
-----------------------*/
/*-----------------
	17. Leave
-----------------------*/
/*-----------------
	18. Employee
-----------------------*/
/*-----------------
	19. Events
-----------------------*/
/*-----------------
	20. Profile
-----------------------*/
/*-----------------
	21. Notifications
-----------------------*/
/*-----------------
	22. Roles & Permissions
-----------------------*/
/*-----------------
	23. Chat Right Sidebar
-----------------------*/
/*-----------------
	24. Invoice
-----------------------*/
/*-----------------
	25. Doctors
-----------------------*/
/*-----------------
	26. Add Doctor
-----------------------*/
/*-----------------
	Doctor Profile
-----------------------*/
/*-----------------
	Staffs
-----------------------*/
/*-----------------
	27. Payslip
-----------------------*/
/*-----------------
	28. Attendance
-----------------------*/
/*-----------------
	29. Inbox
-----------------------*/
/*-----------------
	29. Mail View
-----------------------*/
/*-----------------
	31. Blog
-----------------------*/
/*-----------------
	32. Blog View
-----------------------*/
/*-----------------
	33. UI Kit
-----------------------*/
/*-----------------
	34. Error
-----------------------*/
/*-----------------
	35. Lock Screen
-----------------------*/
/*-----------------
	36. Voice call
-----------------------*/
/*-----------------
	37. Video Call
-----------------------*/
/*-----------------
	38. Incoming Call
-----------------------*/
/*-----------------
	39. Notification settings
-----------------------*/
/*-----------------
	40. Chat Sidebar
-----------------------*/
/*-----------------
	41. Gallery
-----------------------*/
/*-----------------
   42. Settings
-----------------------*/
/*-----------------
	43. Responsive
-----------------------*/
/* invoice */
html {
  height: 100%;
}
body {
  font-family: $font-family_1;
  font-size: 16px;
  color: $color_1;
  background: linear-gradient(
      0deg,
      rgba(51, 53, 72, 0.05),
      rgba(51, 53, 72, 0.05)
    ),
    #ffffff;
  overflow-x: hidden;
  height: 100%;
}
h1 {
  margin-top: 0;
  font-size: 2.25rem;
}
h2 {
  margin-top: 0;
  font-size: 1.875rem;
}
h3 {
  margin-top: 0;
  font-size: 1.5rem;
}
h4 {
  margin-top: 0;
  font-size: 1.125rem;
}
h5 {
  margin-top: 0;
  font-size: 0.875rem;
}
h6 {
  margin-top: 0;
  font-size: 0.75rem;
}
.h1 {
  font-size: 2.25rem;
}
.h2 {
  font-size: 1.875rem;
}
.h3 {
  font-size: 1.5rem;
}
.h4 {
  font-size: 1.125rem;
}
.h5 {
  font-size: 0.875rem;
}
.h6 {
  font-size: 0.75rem;
}
a {
  &:hover {
    text-decoration: none;
    color: $color_2;
    outline: none;
  }
  &:active {
    text-decoration: none;
    color: $color_2;
    outline: none;
  }
  &:focus {
    text-decoration: none;
    color: $color_2;
    outline: none;
  }
  color: $color_3;
  text-decoration: none;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.form-control {
  box-shadow: none;
  padding: 10px 15px;
  font-size: 14px;
  min-height: 45px;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
  &::placeholder {
    font-size: 13px;
  }
  &:focus {
    border-color: $border-color_1;
    box-shadow: none;
    outline: 0 none;
  }
}
input {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  outline: none;
}
button {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  outline: none;
}
input[type="file"] {
  height: auto;
  padding: 6px 0.75rem;
}
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea.form-control {
  resize: vertical;
  height: auto;
  resize: vertical;
  height: auto;
}
.navbar-nav {
  > li {
    float: left;
  }
  .open {
    .dropdown-menu {
      background-color: $background-color_1;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
.form-group {
  margin-bottom: 20px;
}
.display-block {
  display: block;
}
.btn.rounded {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.btn.focus {
  box-shadow: inherit !important;
}
.btn {
  &:focus {
    box-shadow: inherit !important;
  }
  font-size: 0.875rem;
}
.list-group-item {
  border: 1px solid #eaeaea;
}
.table {
  &:not(:first-child) {
    border: 0;
    border-top: 0;
  }

  color: $color_4;
  border: 1px solid #f0f0f0;
  > tbody {
    > tr {
      > td {
        font-weight: 300;
      }
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    vertical-align: middle;
    + {
      p {
        color: $color_7;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
  }
  .dropdown-menu.show {
    padding: 0;
  }
  .dropdown-item {
    padding: 7px 10px;
  }
  .dropdown-menu {
    font-size: 12px;
  }
  .invoice-link {
    color: $color_6;
  }
  .bg-success-dark {
    background: #008f64;
  }
  .bg-danger-dark {
    background: #ff0000;
  }
}
table.table {
  td {
    border-color: $border-color_2;
    .avatar {
      margin: 0 5px 0 0;
    }
    h2 {
      display: inline-block;
      font-size: inherit;
      font-weight: 400;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      a {
        color: $color_5;
        &:hover {
          color: $color_6;
        }
      }
      span {
        color: $color_7;
        display: block;
        font-size: 12px;
        margin-top: 3px;
      }
    }
  }
  th {
    border-color: $border-color_2;
  }
}
.table-bordered {
  td {
    border: 1px solid #dee2e6;
  }
  th {
    border: 1px solid #dee2e6;
  }
}
.dataTables_length {
  select {
    appearance: auto;
  }
}
.table.table-white {
  background-color: $background-color_1;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}
.table-nowrap {
  td {
    white-space: nowrap;
  }
  th {
    white-space: nowrap;
  }
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.w-40 {
  width: 40px;
}
.block {
  display: block !important;
}
.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-muted-light {
  color: $color_8;
}
.card-box {
  background-color: $background-color_1;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}
.card-title {
  color: $color_1;
  font-size: 16px;
  margin-bottom: 20px;
  margin-bottom: 1.125rem;
  > a.btn {
    color: $color_16;
  }
}
.page-title {
  color: $color_1;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 25px;
}
.page-sub-title {
  color: $color_9;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}
.cal-icon {
  &:after {
    background: transparent url("./img/icons/calendar-icon.svg") no-repeat
      scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    height: 24px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 0;
    width: 24px;
    cursor: pointer;
  }
  position: relative;
  width: 100%;
}
.time-icon {
  &:after {
    background: transparent url("./img/clock.png") no-repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    height: 19px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 0;
    width: 19px;
  }
  position: relative;
  width: 100%;
}
.badge-success-border {
  border: 1px solid #55ce63;
  color: $color_10;
  background-color: $background-color_1;
  display: inline-block;
  min-width: 80px;
}
.badge-danger-border {
  border: 1px solid #f62d51;
  color: $color_11;
  background-color: $background-color_1;
  display: inline-block;
  min-width: 80px;
}
.badge-warning-border {
  border: 1px solid #ffbc34;
  color: $color_12;
  background-color: $background-color_1;
  display: inline-block;
  min-width: 80px;
}
.badge-info-border {
  border: 1px solid #009efb;
  color: $color_6;
  background-color: $background-color_1;
  display: inline-block;
  min-width: 80px;
}
.modal-footer.text-start {
  text-align: left;
}
.modal-footer.text-center {
  text-align: center;
}
.font-18 {
  font-size: 18px;
}
.border-right {
  border-right: 1px solid #e7e7e7;
}
.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
.btn-white {
  background-color: $background-color_1;
  border: 1px solid #ccc;
  color: $color_13;
}
.rounded {
  border-radius: 500px;
}
.circle {
  border-radius: 500px;
}
.bg-primary {
  background-color: $background-color_3 !important;
}
.badge-primary {
  background-color: $background-color_3 !important;
  color: $color_16;
}
.bg-success {
  background-color: $background-color_4 !important;
}
.badge-success {
  background-color: $background-color_4 !important;
}
.bg-info {
  background-color: $background-color_3 !important;
}
.badge-info {
  background-color: $background-color_3 !important;
}
.bg-warning {
  background-color: $background-color_5 !important;
}
.badge-warning {
  background-color: $background-color_5 !important;
}
.bg-danger {
  background-color: $background-color_6 !important;
}
.badge-danger {
  background-color: $background-color_6 !important;
}
.bg-white {
  background-color: $background-color_1;
}
.text-primary {
  color: $color_14 !important;
}
.dropdown-menu {
  > li {
    > a.text-primary {
      color: $color_14 !important;
    }
    > a.text-success {
      color: $color_10;
    }
    > a.text-danger {
      color: $color_11;
    }
    > a.text-info {
      color: $color_6;
    }
    > a.text-warning {
      color: $color_12;
    }
    > a.text-purple {
      color: $color_15;
    }
  }
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: $background-color_1;
  font-size: 13px;
}
.text-success {
  color: $color_10;
}
.text-danger {
  color: $color_11;
}
.text-info {
  color: $color_6;
}
.text-warning {
  color: $color_12;
}
.text-purple {
  color: $color_15;
}
.btn-primary {
  border-color: $border-color_3;
  background-color: $background-color_7;
  border-radius: 8px;
  &:hover {
    background-color: $background-color_8;
    border: 1px solid #00d3c7;
    color: $color_16;
  }
  &:focus {
    background-color: $background-color_8;
    border: 1px solid #00d3c7;
    color: $color_16;
  }
  &:active {
    background-color: $background-color_8;
    border: 1px solid #00d3c7;
    color: $color_16;
    &:focus {
      background-color: $background-color_3;
      border: 1px solid #009efb;
    }
    &:hover {
      background-color: $background-color_3;
      border: 1px solid #009efb;
    }
    &:not(:disabled) {
      &:not(.disabled) {
        background-color: $background-color_3;
        border-color: $border-color_4;
        color: $color_16;
      }
    }
  }
}
.btn-primary.active {
  background-color: $background-color_8;
  border: 1px solid #00d3c7;
  color: $color_16;
  &:focus {
    background-color: $background-color_3;
    border: 1px solid #009efb;
  }
  &:hover {
    background-color: $background-color_3;
    border: 1px solid #009efb;
  }
  &:not(:disabled) {
    &:not(.disabled) {
      background-color: $background-color_3;
      border-color: $border-color_4;
      color: $color_16;
    }
  }
}
.open {
  > .dropdown-toggle.btn-primary {
    background-color: $background-color_8;
    border: 1px solid #00d3c7;
    color: $color_16;
    &:focus {
      background-color: $background-color_3;
      border: 1px solid #009efb;
    }
    &:hover {
      background-color: $background-color_3;
      border: 1px solid #009efb;
    }
  }
  > .dropdown-toggle.btn-primary.focus {
    background-color: $background-color_3;
    border: 1px solid #009efb;
  }
  > .dropdown-toggle.btn-success {
    background: #4ab657;
    border: 1px solid #4ab657;
    &:focus {
      background: #4ab657;
      border: 1px solid #4ab657;
    }
    &:hover {
      background: #4ab657;
      border: 1px solid #4ab657;
    }
  }
  > .dropdown-toggle.btn-success.focus {
    background: #4ab657;
    border: 1px solid #4ab657;
  }
  > .dropdown-toggle.btn-info {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
    &:focus {
      background-color: $background-color_9;
      border: 1px solid #028ee1;
    }
    &:hover {
      background-color: $background-color_9;
      border: 1px solid #028ee1;
    }
  }
  > .dropdown-toggle.btn-info.focus {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
  }
  > .dropdown-toggle.btn-warning {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
    &:focus {
      background: #e9ab2e;
      border: 1px solid #e9ab2e;
    }
    &:hover {
      background: #e9ab2e;
      border: 1px solid #e9ab2e;
    }
  }
  > .dropdown-toggle.btn-warning.focus {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
  }
}
.btn-primary.active.focus {
  background-color: $background-color_3;
  border: 1px solid #009efb;
}
.btn-primary.focus {
  &:active {
    background-color: $background-color_3;
    border: 1px solid #009efb;
  }
}
.show {
  > .btn-primary.dropdown-toggle {
    background-color: $background-color_3;
    border-color: $border-color_4;
    color: $color_16;
  }
  > .btn-outline-primary.dropdown-toggle {
    background-color: $background-color_3;
    border-color: $border-color_4;
    color: $color_16;
  }
}
.btn-outline-primary {
  border-color: $border-color_4;
  color: $color_6;
  &:hover {
    background-color: $background-color_3;
    border-color: $border-color_4;
    color: $color_16;
  }
  &:active {
    &:not(:disabled) {
      &:not(.disabled) {
        background-color: $background-color_3;
        border-color: $border-color_4;
        color: $color_16;
      }
    }
  }
}
.btn-outline-primary.active {
  &:not(:disabled) {
    &:not(.disabled) {
      background-color: $background-color_3;
      border-color: $border-color_4;
      color: $color_16;
    }
  }
}
.btn-success {
  background-color: $background-color_4;
  border: 1px solid #55ce63;
  &:hover {
    background: #4ab657;
    border: 1px solid #4ab657;
  }
  &:focus {
    background: #4ab657;
    border: 1px solid #4ab657;
  }
  &:active {
    background: #4ab657;
    border: 1px solid #4ab657;
    &:focus {
      background: #4ab657;
      border: 1px solid #4ab657;
    }
    &:hover {
      background: #4ab657;
      border: 1px solid #4ab657;
    }
  }
}
.btn-success.active {
  background: #4ab657;
  border: 1px solid #4ab657;
  &:focus {
    background: #4ab657;
    border: 1px solid #4ab657;
  }
  &:hover {
    background: #4ab657;
    border: 1px solid #4ab657;
  }
}
.btn-success.active.focus {
  background: #4ab657;
  border: 1px solid #4ab657;
}
.btn-success.focus {
  &:active {
    background: #4ab657;
    border: 1px solid #4ab657;
  }
}
.btn-info {
  background-color: $background-color_3;
  border: 1px solid #009efb;
  &:hover {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
  }
  &:focus {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
  }
  &:active {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
    &:focus {
      background-color: $background-color_9;
      border: 1px solid #028ee1;
    }
    &:hover {
      background-color: $background-color_9;
      border: 1px solid #028ee1;
    }
  }
}
.btn-info.active {
  background-color: $background-color_9;
  border: 1px solid #028ee1;
  &:focus {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
  }
  &:hover {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
  }
}
.btn-info.active.focus {
  background-color: $background-color_9;
  border: 1px solid #028ee1;
}
.btn-info.focus {
  &:active {
    background-color: $background-color_9;
    border: 1px solid #028ee1;
  }
}
.btn-warning {
  background: #ffbc34;
  border: 1px solid #ffbc34;
  &:hover {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
  }
  &:focus {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
  }
  &:active {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
    &:focus {
      background: #e9ab2e;
      border: 1px solid #e9ab2e;
    }
    &:hover {
      background: #e9ab2e;
      border: 1px solid #e9ab2e;
    }
  }
}
.btn-warning.active {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
  &:focus {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
  }
  &:hover {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
  }
}
.btn-warning.active.focus {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.focus {
  &:active {
    background: #e9ab2e;
    border: 1px solid #e9ab2e;
  }
}
.btn-danger {
  background: #f62d51;
  border: 1px solid #f62d51;
  &:hover {
    background-color: $background-color_10;
    border: 1px solid #e6294b;
  }
  &:focus {
    background-color: $background-color_10;
    border: 1px solid #e6294b;
  }
}
.pagination {
  > .active {
    > a {
      background-color: $background-color_8;
      border-color: $border-color_5;
      &:focus {
        background-color: $background-color_8;
        border-color: $border-color_5;
      }
      &:hover {
        background-color: $background-color_8;
        border-color: $border-color_5;
      }
    }
    > span {
      background-color: $background-color_8;
      border-color: $border-color_5;
      &:focus {
        background-color: $background-color_8;
        border-color: $border-color_5;
      }
      &:hover {
        background-color: $background-color_8;
        border-color: $border-color_5;
      }
    }
  }
  > li {
    > a {
      color: $color_17;
      font-size: 14px;
      padding: 5px 15px !important;
    }
    > span {
      color: $color_17;
      font-size: 14px;
      padding: 5px 15px !important;
    }
  }
}
.page-item.active {
  .page-link {
    background-color: $background-color_11;
    border-color: $border-color_1;
  }
}
.btn-group-xs {
  > .btn {
    min-height: 22px;
    min-width: 22px;
  }
}
.btn-xs {
  min-height: 22px;
  min-width: 22px;
}
.btn-link {
  box-shadow: none;
  color: $color_13;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
  }
}
.nav {
  .open {
    > a {
      background-color: $background-color_12;
      &:focus {
        background-color: $background-color_12;
      }
      &:hover {
        background-color: $background-color_12;
      }
    }
  }
}
.card {
  margin-bottom: 25px;
  box-shadow: none;
  border: 0;
  border-radius: 12px;
  width: 100%;
  .card-header {
    background-color: $background-color_14;
    border: 0 none;
    font-size: 16px;
    line-height: 30px;
    padding: 15px;
    .card-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 5px 0;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.dropdown-action {
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}
.badge.rounded-pill {
  border-radius: 10rem;
}
.btn.btn-rounded {
  border-radius: 50px;
}
.nav-tabs {
  .nav-link {
    &:focus {
      background-color: $background-color_13;
      border-color: $border-color_3;
    }
    &:hover {
      background-color: $background-color_13;
      border-color: $border-color_3;
    }
    border-radius: 0;
  }
  > li {
    > a {
      margin-right: 0;
      color: $color_20;
      border-radius: 0;
      &:hover {
        border-color: $border-color_3;
        color: $color_13;
      }
      &:focus {
        border-color: $border-color_3;
        color: $color_13;
      }
    }
  }
}
.table.dataTable {
  border-collapse: collapse !important;
}
.progress-example {
  .progress {
    margin-bottom: 1.5rem;
  }
}
.progress-xs {
  height: 4px;
}
.progress-sm {
  height: 15px;
}
.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}
.form-control.form-control-sm {
  padding: 0.25rem 0.5rem;
}
.page-link {
  &:focus {
    box-shadow: unset;
  }
}
.col-form-label {
  font-size: 14px;
  font-weight: 500;
}
.input-group-text {
  height: 45px;
}
.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1039;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(204, 204, 204, 0.25);
  .header-left {
    float: left;
    height: 70px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 220px;
    z-index: 1;
  }
  .navbar-nav {
    .badge {
      position: absolute;
      right: 7px;
      top: 4px;
    }
  }
  .dropdown-menu {
    > li {
      > a {
        position: relative;
      }
    }
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .user-img {
    img {
      width: 40px;
      border-radius: 14px;
    }
  }
}
.logo {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: flex-start;
  span {
    color: $color_3;
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
  }
}
.page-title-box {
  border-radius: 0;
  height: 70px;
  margin-bottom: 0;
  padding: 14px 20px;
  h3 {
    color: $color_16;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
}
.user-menu {
  margin: 0;
  position: relative;
  z-index: 99;
  .user-img {
    .status {
      bottom: 0px;
    }
  }
  .dropdown-menu {
    min-width: 145px;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    display: block;
    transform: translate(0px, 100px);
  }
  .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
  .bg-primary {
    background-color: $background-color_3 !important;
  }
}
.user-menu.nav {
  > li {
    > a {
      color: $color_16;
      font-size: 16px;
      line-height: 70px;
      padding: 0 5px;
      height: 70px;
      &:hover {
        background-color: $background-color_13;
      }
      &:focus {
        background-color: $background-color_13;
      }
      .badge {
        color: $color_16;
        font-size: 60%;
        font-weight: 700;
        position: absolute;
        right: 3px;
        top: 6px;
      }
      > i {
        font-size: 16px;
        line-height: 55px;
      }
    }
  }
  li.user-profile-list {
    a {
      line-height: 20px;
    }
  }
  li {
    margin-right: 12px;
    position: relative;
  }
}
.user-img {
  display: inline-block;
  position: relative;
  .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 0;
    width: 10px;
  }
}
.dropdown-menu.show {
  padding: 0;
}
.status {
  background-color: $background-color_15;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}
.status.online {
  background-color: $background-color_4;
}
.status.offline {
  background-color: $background-color_6;
}
.status.away {
  background-color: $background-color_16;
}
.top-nav-search {
  float: left;
  margin-left: 22px;
  form {
    margin-top: 13px;
    position: relative;
    width: 270px;
  }
  .form-control {
    background: rgba(46, 55, 164, 0.05);
    border-radius: 12px;
    border: 0;
    height: 40px;
    padding: 10px 59px 10px 40px;
    &::-webkit-input-placeholder {
      color: $color_18;
      font-weight: 500;
    }
    &::-moz-placeholder {
      color: $color_18;
      font-weight: 500;
    }
    &:-ms-input-placeholder {
      color: $color_18;
      font-weight: 500;
    }
    &::-ms-input-placeholder {
      color: $color_18;
      font-weight: 500;
    }
    &::placeholder {
      color: $color_18;
      font-weight: 500;
    }
  }
  .btn {
    background-color: $background-color_17;
    border-color: $border-color_3;
    min-height: 45px;
    padding: 7px 10px;
    position: absolute;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.theme-set {
  .form-control {
    line-height: 29px;
  }
}
.user-profile-list {
  .user-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 10px !important;
  }
}
.user-names {
  padding-right: 10px;
  text-align: right;
  h5 {
    color: $color_3;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  span {
    color: $color_19;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.sidebar {
  top: 90px;
  width: 250px;
  z-index: 1039;
  background-color: $background-color_1;
  bottom: 0;
  margin-top: 0px;
  position: fixed;
  left: 0;
  transition: all 0.2s ease-in-out;
  border-top-right-radius: 40px;
  box-shadow: 5px 20px 14px rgba(46, 55, 164, 0.05);
  overflow-y: hidden;
  .sidebar-menu {
    > ul {
      > li {
        > a {
          span {
            transition: all 0.2s ease-in-out 0s;
            margin-left: 10px;
            white-space: nowrap;
          }
          span.badge {
            color: $color_16;
            margin-left: auto;
          }
        }
      }
    }
  }
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
  ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      .active {
        img {
          filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg)
            brightness(50%) contrast(340%);
          transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
        }
        color: $color_3;
        font-weight: 500;
      }
      a {
        i {
          font-size: 18px;
          min-height: 24px;
          line-height: 18px;
          transition: all 0.2s ease-in-out 0s;
          // background: rgba(46, 55, 164, 0.05);
          border-radius: 8px;
          width: 40px;
          height: 40px;
          margin-left: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
        }
        span.menu-side {
          font-size: 18px;
          min-height: 24px;
          line-height: 18px;
          transition: all 0.2s ease-in-out 0s;
          background: rgba(46, 55, 164, 0.05);
          border-radius: 8px;
          width: 40px;
          height: 40px;
          margin-left: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
        }
      }
    }
    .submenu {
      .active {
        color: $color_3;
        font-weight: 500;
        img {
          filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg)
            brightness(50%) contrast(340%);
          transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
        }
      }
      ul {
        .active {
          position: relative;
          &:before {
            background-image: url(./img/icons/right-icon.svg);
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            top: 50%;
            left: 35px;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
          }
        }
      }
    }
    ul {
      a.active {
        color: $color_3;
        font-weight: 500;
      }
      a {
        display: block;
        font-size: 14px;
        padding: 9px 10px 9px 60px;
        position: relative;
        color: $color_19;
        font-weight: 500;
        .menu-arrow {
          top: 6px;
        }
      }
      display: block;
      ul {
        a {
          padding-left: 70px;
        }
        ul {
          a {
            padding-left: 90px;
          }
        }
      }
    }
    li.menu-title {
      a {
        i {
          font-size: 16px;
          margin-right: 0;
          text-align: right;
          width: auto;
        }
      }
    }
  }
  li {
    a {
      color: $color_20;
      display: block;
      font-size: 15px;
      height: auto;
      padding: 0 20px;
      &:hover {
        color: $color_17;
        img {
          filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg)
            brightness(50%) contrast(340%);
          transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
        }
      }
    }
    a.subdrop {
      .menu-arrow {
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
  li.active {
    a {
      color: $color_17;
    }
  }
  li.menu-title {
    a {
      color: $color_6;
      display: inline-block;
      float: right;
      padding: 0;
    }
    a.btn {
      color: $color_16;
      display: block;
      float: none;
      font-size: 15px;
      line-height: inherit;
      margin-bottom: 15px;
    }
  }
  > ul {
    > li {
      position: relative;
      > a {
        padding: 12px 20px;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        position: relative;
        transition: all 0.2s ease-in-out 0s;
        color: $color_21;
        font-weight: 500;
      }
    }
  }
  .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
    position: absolute;
    right: 25px;
    display: inline-block;
    font-family: $font-family_2;
    text-rendering: auto;
    line-height: 40px;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 23px;
    &:before {
      content: "\f105";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
    }
  }
  a {
    transition: unset;
    -moz-transition: unset;
    -o-transition: unset;
    -ms-transition: unset;
    -webkit-transition: unset;
  }
}
.menu-title {
  color: $color_13;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 20px;
  > i {
    float: right;
    line-height: 40px;
  }
}
.mobile-user-menu {
  color: $color_16;
  display: none;
  font-size: 24px;
  height: 70px;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 50px;
  z-index: 10;
  > a {
    color: $color_16;
    padding: 0;
    &:hover {
      color: $color_16;
    }
  }
  > .dropdown-menu {
    > a {
      line-height: 23px;
    }
  }
}
.profile-rightbar {
  display: none !important;
  color: $color_6;
  font-size: 26px;
  margin-left: 15px;
}
.fixed-sidebar-right {
  position: fixed;
  top: 60px;
  right: 0;
  width: 300px;
  margin-right: -300px;
  bottom: 0;
  z-index: 101;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mobile_btn {
  display: none;
}
.logout-btn {
  margin: 50px 0px;
  a {
    span.menu-side {
      font-size: 18px;
      min-height: 24px;
      line-height: 18px;
      transition: all 0.2s ease-in-out 0s;
      background: rgba(46, 55, 164, 0.05);
      border-radius: 8px;
      width: 40px;
      height: 40px;
      margin-left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
    padding: 12px 20px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
    color: $color_3;
    font-weight: 500;
    span {
      margin-left: 10px;
    }
  }
}
.page-wrapper {
  left: 0;
  margin-left: 50px;
  padding-top: 70px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  > .content {
    padding: 30px;
    padding-bottom: 0;
  }
}
.login-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.login-wrapper {
  .loginbox {
    .login-right {
      .login-right-wrap {
        max-width: 100%;
        flex: 0 0 100%;
        .form-group {
          position: relative;
          label {
            font-size: 12px;
            color: $color_1;
            font-weight: 500;
            position: absolute;
            top: -10px;
            left: 10px;
            background: #fff;
            margin-bottom: 0;
            padding: 0px 5px;
          }
        }
      }
      h2 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 40px;
      }
      .forgotpass {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 40px;
        a {
          color: $color_24;
          font-size: 14px;
          font-weight: 500;
          margin-left: auto;
          &:hover {
            color: $color_13;
            text-decoration: underline;
          }
        }
      }
      .dont-have {
        color: $color_25;
        margin: 20px 0px;
        a {
          color: $color_26;
          font-weight: 500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      padding: 50px;
      width: 100%;
    }
    background: #ffffff;
    box-shadow: 0px 80px 80px rgba(46, 55, 164, 0.03);
    border-radius: 24px;
    display: flex;
    max-width: 830px;
    width: 100%;
  }
  .login-right {
    .user-lock-screen {
      h2 {
        margin-bottom: 10px;
      }
    }
  }
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.login-danger {
  color: $color_22;
}
.star-red {
  color: $color_22;
  color: $color_93;
}
.account-subtitle {
  color: $color_23;
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;
  font-weight: 500;
  a {
    color: $color_3;
  }
}
.remember-me {
  .custom_check {
    color: $color_1;
    font-size: 13px;
    font-weight: 500;
    .checkmark {
      width: 18px;
      height: 18px;
      top: 2px;
      left: 2px;
      border: 2px solid rgba(51, 52, 72, 0.2);
      border-radius: 4px;
      background-color: $background-color_1;
      &::after {
        left: 3px;
        top: 0px;
      }
    }
  }
}
.social-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  > span {
    color: $color_25;
    margin-right: 8px;
  }
  > a {
    background: rgba(51, 52, 72, 0.05);
    border-radius: 12px;
    height: 40px;
    margin-right: 6px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:hover {
      background-color: $background-color_18;
      color: $color_16;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.btn-primary.primary-reset {
  background-color: $background-color_19;
  border-color: $border-color_6;
  &:hover {
    background-color: $background-color_20;
    border: 1px solid #3d5ee1;
  }
}
.profile-views {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: $color_27;
  cursor: pointer;
}
.login-right {
  .form-control {
    border: 2px solid rgba(46, 55, 164, 0.1);
    border-radius: 10px;
    height: 45px;
    &:focus {
      border: 2px solid rgb(74 83 199 / 80%);
    }
  }
  .btn-primary {
    background: linear-gradient(92.39deg, #4e57cd 1.86%, #2e37a4 100%);
    border-radius: 12px;
    font-size: 16px;
    padding: 10px 15px;
    &:hover {
      background: linear-gradient(92.39deg, #2e37a4 1.86%, #00318b 100%);
      border: 1px solid #00318b;
    }
  }
}
.btn-block {
  width: 100%;
}
.account-logo {
  margin-bottom: 40px;
  text-align: left;
}
.next-sign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.login-btn {
  margin-bottom: 40px;
}
.user-lock-screen {
  p {
    margin-bottom: 0;
    color: $color_1;
    font-size: 14px;
    font-weight: 500;
  }
  margin-bottom: 30px;
}
.sucess-mail {
  h4 {
    font-size: 26px;
    font-weight: 600;
    margin: 15px 0px 20px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
  }
}
.login-sec {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: auto;
}
.log-img {
  img {
    margin: 0 auto;
  }
}
.login-wrap {
  background-color: $background-color_7;
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  padding: 0px;
  border-radius: 0px 65px 65px 0px;
  .login-sec {
    position: absolute;
    background-image: url("./img/login-0l.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover, auto;
    width: 100%;
  }
}
.login-wrap-bg {
  position: absolute;
  right: 0;
  padding: 15px 90px 15px;
  height: 100%;
}
.dash-widget {
  background-color: $background-color_1;
  border-radius: 12px;
  backdrop-filter: blur(2px);
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}
.dash-boxs {
  background: rgba(46, 55, 164, 0.05);
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 12px;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.dash-content {
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  h2 {
    color: $color_3;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    color: $color_23;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    .negative-view {
      color: $color_28;
    }
    .passive-view {
      color: $color_24;
    }
  }
  h5 {
    font-size: 24px;
    color: $color_3;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      color: $color_23;
    }
  }
}
.comman-flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.card-table.card-table-top {
  .card-body {
    height: 175px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.chart-title {
  margin-bottom: 25px;
  h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    color: $color_13;
  }
  // span {
  // 	color: $color_6;
  // }
}
ul.chat-user-total {
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: $color_1;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    i.current-users {
      color: $color_3;
      padding-right: 5px;
      font-size: 8px;
    }
    i.low-users {
      color: $color_29;
      padding-right: 5px;
      font-size: 8px;
    }
    i.old-users {
      color: $color_24;
      padding-right: 5px;
      font-size: 8px;
    }
  }
}
.new-patient-table {
  display: table;
  td {
    vertical-align: middle;
  }
  th {
    vertical-align: middle;
  }
  tr {
    td {
      img {
        margin-right: 5px;
      }
    }
  }
}
.btn-primary-one {
  border: 1px solid #ffbc35;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: $color_4;
}
.btn-primary-two {
  border: 1px solid #009efb;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: $color_4;
}
.btn-primary-three {
  border: 1px solid #55ce63;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: $color_4;
}
.btn-primary-four {
  border: 1px solid #ffbc35;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: $color_4;
}
.bar-chart {
  position: relative;
  width: 100%;
  margin-top: 15px;
  > .legend {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    margin-bottom: -45px;
    border-top: 1px solid #000;
    > .item {
      position: relative;
      display: inline-block;
      float: left;
      width: 25%;
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 1px;
        height: 8px;
        background-color: $background-color_21;
        margin-top: -8px;
      }
      h4 {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-top: 5px;
      }
    }
    > .item.text-end {
      &:before {
        right: 0;
        left: auto;
      }
    }
  }
  > .chart {
    position: relative;
    width: 100%;
    > .item {
      position: relative;
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
      color: $color_16;
      text-transform: uppercase;
      > .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $background-color_22;
        z-index: 5;
        > .percent {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          height: 40px;
          line-height: 40px;
          padding-right: 12px;
          z-index: 15;
        }
        > .item-progress {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: $background-color_3;
          z-index: 10;
          > .title {
            display: block;
            position: absolute;
            height: 40px;
            line-height: 40px;
            padding-left: 12px;
            letter-spacing: 2px;
            z-index: 15;
          }
        }
      }
    }
  }
}
.hospital-barchart {
  h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: $color_13;
  }
}
.custom-table {
  tr {
    background-color: $background-color_1;
    white-space: nowrap;
  }
  tbody {
    tr {
      box-shadow: 0 0 3px #e5e5e5;
      border: 0;
      td {
        border: 0;
        font-size: 14px;
      }
    }
  }
  thead {
    tr {
      th {
        border: 0;
        font-size: 14px;
        color: $color_23;
      }
    }
  }
  tr.holiday-completed {
    color: $color_8;
  }
  .bold-total {
    td {
      font-weight: 600;
    }
    .form-control {
      font-weight: 600;
    }
  }
}
.table.custom-table {
  > tbody {
    > tr {
      > td {
        padding: 10px 15px;
        vertical-align: middle;
      }
      > th {
        padding: 10px 15px;
        vertical-align: middle;
      }
    }
  }
  > tfoot {
    > tr {
      > td {
        padding: 10px 15px;
        vertical-align: middle;
      }
      > th {
        padding: 10px 15px;
        vertical-align: middle;
      }
    }
  }
  > thead {
    > tr {
      > td {
        padding: 10px 15px;
        vertical-align: middle;
      }
      > th {
        padding: 10px 15px;
        vertical-align: middle;
      }
    }
  }
}
.member-panel {
  .contact-list {
    > li {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .card-body {
    overflow-y: auto;
    height: 245px;
    -webkit-overflow-scrolling: touch;
    padding: 0;
  }
}
.contact-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  > li {
    border-bottom: 1px solid #eaeaea;
    border-radius: inherit;
    padding: 10px;
    background-color: $background-color_1;
  }
}
.contact-cont {
  position: relative;
}
.contact-info {
  padding: 0 30px 0 50px;
}
.contact-date {
  color: $color_20;
  font-size: 12px;
}
.take-btn {
  min-width: 80px;
}
.good-morning-blk {
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 25px;
  position: relative;
  z-index: 9;
  &:before {
    content: "";
    position: absolute;
    background-image: url(./img/bg-img-01.png);
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.morning-user {
  padding: 50px 0px 50px 35px;
  h2 {
    font-size: 28px;
    font-weight: 600;
    span {
      color: $color_3;
    }
  }
  p {
    font-size: 15px;
    font-weight: 500;
    color: $color_23;
    margin-bottom: 0;
  }
}
.morning-img {
  img {
    position: absolute;
    bottom: 0;
  }
}
.position-blk {
  position: relative;
}
.activity-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.activity-boxs {
  background: rgba(46, 55, 164, 0.05);
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 50px;
}
.departments-list {
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: $color_30;
    margin-bottom: 0;
  }
}
.chart-user-icon {
  position: relative;
  img {
    position: absolute;
    top: 65px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
table.dataTable {
  thead {
    .sorting {
      &:before {
        bottom: 15px;
      }
      &:after {
        bottom: 15px;
      }
    }
    .sorting_asc {
      &:before {
        bottom: 15px;
      }
      &:after {
        bottom: 15px;
      }
    }
    .sorting_desc {
      &:before {
        bottom: 15px;
      }
      &:after {
        bottom: 15px;
      }
    }
    .sorting_asc_disabled {
      &:before {
        bottom: 15px;
      }
      &:after {
        bottom: 15px;
      }
    }
    .sorting_desc_disabled {
      &:before {
        bottom: 15px;
      }
      &:after {
        bottom: 15px;
      }
    }
  }
}
.table-dash {
  .dataTables_length {
    display: none;
  }
  .dataTables_info {
    display: none;
  }
  .pagination {
    display: none;
  }
}
.table-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.patient-views {
  color: $color_3;
  font-size: 14px;
  font-weight: 500;
}
.appoint-doctor {
  h2 {
    color: $color_3;
  }
}
.appoint-time {
  span {
    color: $color_23;
    font-size: 12px;
    font-weight: 500;
  }
  h6 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.patient-visit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.doctor-list-blk {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 25px;
}
.doctor-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.doctor-box-icon {
  width: 66px;
  height: 66px;
  background: #2e37a4;
  border-radius: 12px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.doctor-content {
  width: 100%;
  h4 {
    font-size: 24px;
    color: $color_3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    span {
      &:nth-child(2) {
        font-size: 14px;
        color: $color_31;
      }
      &:last-child {
        font-size: 10px;
        border-radius: 20px;
        margin: auto;
        padding: 5px;
      }
    }
  }
  h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.border-right-bg {
  &:before {
    content: "";
    position: absolute;
    background: rgba(46, 55, 164, 0.2);
    height: 44px;
    width: 2px;
    right: 0;
  }
}
.income-value {
  text-align: center;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    span {
      font-size: 14px;
      font-weight: 500;
      color: $color_23;
    }
  }
  p {
    font-size: 12px;
    font-weight: 500;
    color: $color_23;
    margin-bottom: 0;
    span {
      color: $color_24;
    }
  }
}
.patient-structure {
  h5 {
    font-size: 16px;
    font-weight: 500;
    color: $color_3;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    span {
      font-size: 12px;
      padding: 3px 7px;
      border-radius: 20px;
    }
  }
}
.struct-point {
  width: 100%;
}
.check-point {
  border: 0;
  border-radius: 4px;
}
.card.wallet-widget {
  margin-bottom: 25px;
  padding: 15px;
  width: 100%;
}
.main-limit {
  text-align: center;
  p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 24px;
    font-weight: 600;
    color: $color_3;
    margin-bottom: 0;
  }
}
.wallet-widget {
  .circle-bar {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    .circle-progress-icon {
      left: 0;
      position: absolute;
      top: 50%;
      right: 0;
      text-align: center;
      margin: 0 auto;
      transform: translateY(-50%);
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background: rgba(0, 211, 199, 0.1);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
    > div {
      display: inline-block;
      position: relative;
      text-align: center;
      canvas {
        width: 100px;
        height: 100px;
      }
    }
  }
}
.teaching-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.steps-history {
  margin-bottom: 0;
  margin-right: 10px;
  align-items: baseline;
  display: inline-grid;
  padding: 0;
  li {
    margin-bottom: 10px;
    color: $color_30;
    font-weight: 500;
    list-style: none;
    font-size: 12px;
  }
}
.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
  width: 100%;
  .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &:last-child {
      border-color: $border-color_3;
      padding-bottom: 0;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      background-color: $background-color_23;
      width: 7px;
      height: 7px;
      top: 0px;
      left: -4px;
      border-radius: 50px;
    }
  }
}
.doctor-date-list {
  padding: 0;
  li {
    list-style: none;
    color: $color_32;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    span {
      color: $color_30;
      font-size: 12px;
      font-weight: 500;
      padding-left: 15px;
    }
    i {
      font-size: 6px;
    }
    .ongoing-drapt {
      i {
        font-size: 12px;
      }
    }
  }
  .new-dot {
    i {
      font-size: 5px;
    }
  }
  .active-doctor {
    span {
      color: $color_3;
    }
    span.ongoing-drapt {
      color: $color_23;
    }
  }
}
.dolor-activity {
  width: 100%;
  .stick-line {
    text-decoration: line-through;
    color: $color_30;
  }
}
.active-doctor {
  border: 1px solid rgba(46, 55, 164, 0.2);
  box-shadow: 0px 25px 50px rgba(46, 55, 164, 0.07);
  border-radius: 14px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .active-circles {
    color: $color_24;
  }
}
.doctor-sub-list {
  padding: 10px;
  background: #ffffff;
  border: 1px solid rgba(46, 55, 164, 0.2);
  box-shadow: 0px 25px 50px rgba(46, 55, 164, 0.07);
  border-radius: 14px;
  width: 100%;
  position: absolute;
  z-index: 99;
  display: none;
  .dropdown-item {
    padding: 0;
    &:focus {
      background-color: $background-color_17;
    }
    &:hover {
      background-color: $background-color_17;
    }
  }
}
.ongoing-blk {
  position: relative;
}
.schedule-time {
  li {
    width: 30px;
    height: 30px;
    border: 1px solid rgba(46, 55, 164, 0.2);
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}
.schedule-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-top: 1px solid rgba(51, 52, 72, 0.2);
  padding-top: 10px;
}
.appoint-start {
  font-size: 12px;
  border-radius: 10px;
  padding: 10px 20px;
}
.new-dot {
  padding: 3px 10px;
  font-size: 12px;
  margin-left: auto;
  border-radius: 8px;
}
.patient-new-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.average-health {
  h5 {
    margin-bottom: 0;
    background: rgba(46, 55, 164, 0.05);
    border-radius: 8px;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    span {
      color: $color_19;
      font-size: 10px;
    }
  }
}
.calendar {
  header {
    .month {
      font-size: 18px;
    }
  }
  float: left;
  margin-bottom: 0;
}

.report-blk {
  width: 100%;
}
.ongoing-drapt {
  position: relative;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  i {
    background: #ffffff;
    border: 1px solid rgba(46, 55, 164, 0.2);
    box-shadow: 0px 25px 50px rgba(46, 55, 164, 0.07);
    border-radius: 6px;
    color: $color_3;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}
.active-doctor.dropdown-toggle {
  &::after {
    display: none;
  }
}
.note-checkit {
  .custom_check {
    .checkmark {
      border: 2px solid rgba(46, 55, 164, 0.2);
      border-radius: 5px;
    }
    input {
      &:checked {
        ~ {
          .checkmark {
            background-color: $background-color_7;
            border-color: $border-color_7;
          }
        }
      }
    }
    color: $color_32;
    font-size: 12px;
    font-weight: 600;
  }
}
.wallet-widget.general-health {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -ms-flex-pack: space-around;
  flex-direction: inherit;
}
.general-health {
  .main-limit {
    p {
      font-weight: 600;
    }
  }
}
.general-health.wallet-widget {
  .circle-bar {
    > div {
      canvas {
        width: 135px;
        height: 135px;
      }
    }
  }
}
.status-gray.re-shedule {
  color: $color_3;
  font-weight: 600;
  font-size: 12px;
}
.patient-table {
  tbody {
    tr {
      td {
        font-weight: 500;
        color: $color_1;
        h2 {
          font-weight: 500;
          color: $color_1;
        }
        &:nth-child(3) {
          color: $color_23;
        }
      }
    }
  }
}
.treat-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.user-imgs-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50px;
    margin-right: 20px;
    border: 2px solid rgba(46, 55, 164, 0.2);
  }
}
.active-user-detail {
  h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    color: $color_23;
    margin-bottom: 0;
  }
}

.weight-blk {
  .slick-slide {
    transition: all ease-in-out 0.3s;
    opacity: 0.2;
    div {
      text-align: center;
    }
    span {
      opacity: 0;
    }
    h4 {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  .slick-current {
    span {
      opacity: 1;
      font-size: 12px;
    }
    opacity: 1;
  }
  // .slick-active {
  // 	opacity: .5;
  // }

  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 14px;
  padding: 25px 0px 20px;
  position: relative;
  &:before {
    background-image: url(./img/icons/mass-icon.svg);
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    left: 50%;
    top: 0px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
.max-blk {
  width: 50%;
}
.report-head {
  h4 {
    margin-bottom: 0;
    font-size: 18px;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
.weight-bar.progress {
  height: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 40px 0px 10px;
}
.weight-bar {
  .progress-bar {
    width: 100px;
    background-color: $background-color_7;
    border-radius: 20px;
  }
}
.weight-checkit {
  list-style: none;
  padding: 0;
  color: $color_23;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.pulse {
  display: block;
  position: absolute;
  top: 24px;
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00d3c7;
  &:before {
    content: "";
    top: 0px;
    right: 0px;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    background: rgb(0 211 199 / 50%);
    cursor: pointer;
    box-shadow: 0 0 0 rgb(0 211 199);
    animation: pulse 1s linear infinite;
    z-index: 1;
  }
}
.activity-box {
  position: relative;
}
.activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  > li {
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    &:last-child {
      .activity-content {
        margin-bottom: 0;
      }
    }
    .activity-user {
      background: #fff;
      height: 32px;
      left: -7px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 3px;
      width: 32px;
    }
    .activity-content {
      background-color: $background-color_1;
      margin: 0 0 20px 40px;
      padding: 0;
      position: relative;
    }
    .time {
      color: $color_23;
      display: block;
      font-size: 12px;
      line-height: 1.35;
    }
    &:hover {
      .activity-delete {
        display: inline-block;
      }
    }
  }
  &::before {
    background: rgba(46, 55, 164, 0.1);
    bottom: 0;
    content: "";
    left: 23px;
    position: absolute;
    top: 8px;
    width: 2px;
  }
}
.activity {
  .activity-list {
    > li {
      .activity-user {
        height: 32px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 12px;
        left: 8px;
        width: 32px;
      }
      .activity-content {
        margin: 0 0 0 40px;
        padding: 0;
        position: relative;
      }
    }
  }
}
.activity-user {
  .avatar {
    height: 32px;
    line-height: 32px;
    margin: 0;
    width: 32px;
  }
}
.activity-delete {
  background-color: $background-color_24;
  border: 1px solid #fe0000;
  color: $color_33;
  display: none;
  font-size: 20px;
  height: 20px;
  line-height: 18px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 20px;
  &:hover {
    background-color: $background-color_25;
    color: $color_16;
  }
}
.timeline-group {
  margin-right: 20px;
  min-width: 100px;
  h4 {
    color: $color_1;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.timeline-group-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 100%;
  h3 {
    color: $color_3;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    span {
      color: $color_1;
      font-size: 14px;
      font-weight: 500;
    }
  }
  p {
    color: $color_23;
    margin-bottom: 0px;
    font-size: 12px;
  }
}
.activity-sub-list {
  li {
    margin-right: 10px;
    img {
      border-radius: 8px;
    }
  }
}
.activity-maps {
  border-radius: 8px;
  border: 2px solid #fff;
  iframe {
    width: 100%;
  }
}
.cdk-overlay-pane {
  font-size: 14px;
}

.chart-title .mat-mdc-select-arrow {
  left: 6px;
}
.select2-container {
  .select2-selection--single {
    border: 2px solid rgba(46, 55, 164, 0.1);
    border-radius: 10px;
    height: 40px;
    .select2-selection__rendered {
      padding-right: 30px;
      padding-left: 15px;
    }
  }
  .select2-selection--multiple {
    min-height: 40px;
  }
}
.select2-container--default {
  .select2-selection--single {
    .select2-selection__arrow {
      height: 38px;
      right: 7px;
      b {
        left: 50%;
        margin-left: -10px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        border-top: 0;
        border-left: 0;
        border-bottom: 1px solid #171717;
        border-right: 1px solid #171717;
        content: "";
        height: 6px;
        display: inline-block;
        pointer-events: none;
        -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        width: 6px;
        vertical-align: 0px;
      }
    }
    .select2-selection__rendered {
      color: $color_34;
      font-size: 14px;
      font-weight: normal;
      line-height: 38px;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $background-color_11;
  }
  .select2-selection--multiple {
    border: 1px solid #eaeaea;
    border-radius: 0;
  }
}
.select2-container--default.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
        border-top: 0;
        border-left: 0;
        border-bottom: 1px solid #171717;
        border-right: 1px solid #171717;
      }
    }
  }
}
.select2-container--default.select2-container--focus {
  .select2-selection--multiple {
    border: 1px solid #eaeaea;
    border-radius: 0;
  }
}
.select2-results__option {
  font-size: 14px;
}
.nav-tabs.nav-justified {
  > li {
    > a {
      border-radius: 0;
      margin-bottom: 0;
    }
  }
}
.nav-tabs.nav-justified.nav-tabs-solid {
  > li {
    > a {
      border-color: $border-color_3;
    }
  }
}
.nav-tabs.nav-tabs-solid {
  > li {
    > a {
      color: $color_13;
    }
    > a.active {
      background-color: $background-color_11;
      border-color: $border-color_1;
      color: $color_16;
      &:hover {
        background-color: $background-color_11;
        border-color: $border-color_1;
        color: $color_16;
      }
      &:focus {
        background-color: $background-color_11;
        border-color: $border-color_1;
        color: $color_16;
      }
    }
  }
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
  > li {
    > a {
      border-radius: 50px;
    }
    > a.active {
      border-radius: 50px;
      &:hover {
        border-radius: 50px;
      }
      &:focus {
        border-radius: 50px;
      }
    }
  }
}
.nav-tabs-justified {
  > li {
    > a {
      border-radius: 0;
      margin-bottom: 0;
      &:hover {
        border-bottom-color: $border-bottom-color_1;
      }
      &:focus {
        border-bottom-color: $border-bottom-color_1;
      }
    }
  }
}
.nav-tabs-justified.nav-tabs-solid {
  > li {
    > a {
      border-color: $border-color_3;
    }
  }
}
.modal-header.bg-danger {
  .modal-title {
    color: $color_16;
  }
}
.dropdown.action-label {
  display: inline-block;
}
.action-label {
  > a {
    display: inline-block;
    min-width: 103px;
  }
  .btn-sm {
    padding: 5px;
  }
  .label {
    display: inline-block;
    min-width: 85px;
    padding: 0.5em 0.6em;
  }
  i {
    margin-right: 3px;
  }
  .dropdown-menu {
    > li {
      > a {
        padding: 3px 10px;
      }
    }
  }
}
.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
  img {
    width: 120px;
    height: 120px;
  }
}
.profile-basic {
  margin-left: 140px;
  .cal-icon {
    width: 100%;
  }
}
.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}
.fileupload {
  input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: -3px;
    top: -3px;
    padding: 5px;
  }
}
.btn-text {
  color: $color_16;
}
.media {
  .avatar {
    min-width: 36px;
  }
  display: flex;
  align-items: center;
}
.avatar {
  background-color: $background-color_26;
  border-radius: 50%;
  color: $color_16;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  &:hover {
    color: $color_16;
  }
  > img {
    width: 100%;
    display: block;
  }
}
.card.chat-box {
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}
.card.chat-box-clinic {
  padding: 0;
  border-radius: 8px;
  width: 100%;
}
.chat-box-clinic {
  .chat-user-group {
    padding: 10px 20px;
    margin-bottom: 20px;
    &:hover {
      background: #2e37a4;
      .user-titles {
        h5 {
          color: $color_35;
        }
      }
      .chat-user-time {
        p {
          color: $color_35;
        }
      }
      .user-text {
        p {
          color: $color_36;
        }
      }
    }
  }
  .chat-search {
    padding: 10px 20px;
    margin-bottom: 20px;
  }
}
.chat-user-group {
  &:hover {
    .chat-user-count {
      span {
        background: #fff;
        color: $color_3;
      }
    }
  }
  margin-bottom: 30px;
  .img-users {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }
  .active-users {
    width: 8px;
    height: 8px;
    right: 5px;
    top: 0px;
  }
  .user-titles {
    h5 {
      color: $color_1;
    }
  }
  .user-titles.user-head-compse {
    h5 {
      color: $color_3;
    }
  }
}
.chat-user-group-head {
  .img-users {
    margin-right: 10px;
  }
  padding: 20px;
  .user-titles {
    h5 {
      color: $color_3;
    }
  }
}
.chat-users {
  width: 100%;
}
.chat-users.user-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.user-dot-list {
  width: 40px;
  height: 40px;
  background: rgba(51, 52, 72, 0.05);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  a {
    color: $color_37;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.chat-user-time {
  margin-left: auto;
  p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: $color_30;
  }
}
.chat-user-count {
  margin-left: auto;
  span {
    background: #2e37a4;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: $color_16;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .user-check {
    background: none;
    color: $color_38;
    font-size: 14px;
  }
}
.user-titles {
  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
  }
}
.user-text {
  p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: $color_30;
  }
}
.user-check.active {
  color: $color_39;
}
.img-users {
  position: relative;
}
.active-users {
  width: 10px;
  height: 10px;
  background: green;
  border-radius: 50px;
  position: absolute;
  right: 0;
  border: 1px solid #ffffff;
}
.call-user {
  img {
    width: 45px;
    height: 45px;
    border-radius: 50px;
  }
}
.call-list-add {
  li {
    a {
      background: rgba(46, 55, 164, 0.05);
      border-radius: 12px;
      width: 50px;
      height: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      color: $color_1;
      font-size: 14px;
      font-weight: 600;
    }
    list-style: none;
    margin-right: 10px;
    &:first-child {
      a {
        width: 67px;
      }
    }
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}
.chat-search {
  .form-group {
    position: relative;
    width: 100%;
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}
.add-search {
  a {
    background: rgba(46, 55, 164, 0.05);
    border-radius: 12px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: $color_3;
    &:hover {
      background: #223d9f;
      i {
        color: $color_16;
      }
    }
  }
}
.top-liv-search.top-chat-search {
  .btn {
    left: 0;
    right: inherit;
    left: 0;
    right: inherit;
  }
  .form-control {
    padding-left: 40px;
    background: rgba(46, 55, 164, 0.05);
    border-radius: 12px;
    border: 0;
    &::placeholder {
      color: $color_18;
      font-size: 12px;
    }
  }
}
.chat-search-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-search-list {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin-bottom: 0;
    li {
      padding: 10px 0px;
      width: 40px;
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin: 0px 10px;
      background: rgba(46, 55, 164, 0.05);
      border-radius: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.card.chat-message-box {
  padding: 20px;
}
.message-sub-box {
  h4 {
    color: $color_40;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    background: rgba(51, 52, 72, 0.1);
    border-radius: 0px 10px 10px 10px;
    padding: 10px;
    margin-bottom: 5px;
    max-width: 270px;
  }
  span {
    color: $color_30;
    font-size: 10px;
    font-weight: 500;
  }
  h5 {
    color: $color_23;
    font-size: 10px;
    font-weight: 500;
  }
}
.media.sent {
  .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
  }
  .message-sub-box {
    text-align: right;
    p {
      background: rgba(46, 55, 164, 0.1);
      border-radius: 0px 15px 15px 15px;
      color: $color_3;
    }
  }
}
.msg-sub-list {
  margin-bottom: 0;
  li {
    background: rgba(46, 55, 164, 0.05);
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 5px;
    max-width: 270px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.footer-discussion {
  display: inline-flex;
  width: 100%;
  .inputgroups {
    width: 100%;
    margin-top: 30px;
    position: relative;
  }
}
.inputgroups {
  input[type="text"] {
    width: 100%;
    height: 52px;
    padding-left: 32px;
    background: rgba(46, 55, 164, 0.05);
    border-radius: 12px;
    border: 0;
    &::placeholder {
      font-size: 12px;
      color: $color_41;
    }
  }
}
.chat-footer-box {
  border-top: 2px solid rgba(46, 55, 164, 0.05);
  border-radius: 0px 0px 10px 10px;
}
.position-icon {
  position: absolute;
}
.micro-text {
  top: 15px;
  left: 10px;
}
.send-chat {
  background: #234ce3;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  top: 0;
  right: 0;
}
.comman-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.symple-text {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    li {
      list-style: none;
    }
  }
  top: 15px;
  right: 65px;
}
.chat-heading {
  h3 {
    margin-bottom: 20px;
  }
}
.chat-users.chat-user-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.comman-space-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-user-blk {
  .user-titles {
    p {
      color: $color_30;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  .user-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.chat-user-icon {
  margin-left: 15px;
}
.call-all {
  margin-bottom: 25px;
  h4 {
    color: $color_1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    span {
      background: #0dbf0a;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      color: $color_16;
      font-size: 10px;
      margin-left: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
  }
  h5 {
    margin-bottom: 0;
    a {
      color: $color_3;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.voice-call-user {
  margin-bottom: 30px;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-bottom: 10px;
  }
  h3 {
    color: $color_1;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    color: $color_30;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.voice-time {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  max-width: 191px;
  margin: auto;
  h3 {
    font-size: 26px;
    color: $color_3;
    font-weight: 600;
    margin-bottom: 0;
    padding: 10px;
  }
}
.voice-menu {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin: 30px 0px;
    padding: 0;
    li {
      margin-right: 20px;
      list-style: none;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        a {
          background: #2e37a4;
          border: 1px solid #2e37a4;
        }
        img {
          filter: invert(1) brightness(100);
        }
      }
      a {
        width: 50px;
        height: 50px;
        background: rgba(51, 52, 72, 0.05);
        border-radius: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
      }
    }
  }
}
.end-call-voice {
  .btn-primary {
    background: #fe3f51;
    border-radius: 8px;
    min-width: 136px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      background: #234ce3;
      border: 1px solid #234ce3;
    }
  }
}
.video-call-blk.chat-user-group {
  &:hover {
    border-radius: 8px;
    background: rgba(51, 52, 72, 0.05);
    border-radius: 8px;
  }
  padding: 10px;
  margin-bottom: 15px;
}
.video-chat-show {
  .message-sub-box {
    p {
      font-size: 12px;
    }
  }
  ul {
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    margin-bottom: 0px;
    padding: 0;
  }
}
.calling-income {
  h4 {
    color: $color_42;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
.crypto-income-blk {
  .voice-call-user {
    h3 {
      color: $color_42;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    p {
      color: $color_3;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.voice-menu-income {
  .btn-primary {
    width: 60px;
    height: 60px;
    border-radius: 12px;
  }
  .call-remove {
    background: #fe3f51;
  }
  .call-received {
    background: #0dbf0a;
  }
}
.top-liv-search {
  .btn {
    background-color: $background-color_17;
    border-color: $border-color_3;
    color: $color_43;
    min-height: 45px;
    padding: 7px 15px;
    position: absolute;
    right: 0;
    top: 0;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.page-content {
  width: 100%;
}
.join-call {
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
}
.join-video {
  position: relative;
  margin-bottom: 30px;
  img {
    border-radius: 10px;
    object-fit: cover;
  }
  &:hover {
    .overlay-icon {
      opacity: 1;
    }
  }
}
.join-video.video-hide {
  > img {
    display: none;
  }
}
.video-wrap.video-hide {
  > video {
    display: none;
  }
}
.video-avatar {
  color: $color_44;
  background: #5d5d5d;
  font-size: 50px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  h4 {
    font-weight: 700;
    font-size: 24px;
    color: $color_35;
  }
}
.video-avatar.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.join-content {
  padding-bottom: 25px;
  h3 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 37px;
  }
  label {
    font-size: 20px;
    margin-bottom: 1.25rem;
  }
}
.icon-mic {
  background: #31aa52;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: $color_16;
  i {
    color: $color_16;
  }
}
.btn-join {
  font-weight: 700;
  font-size: 20px;
  padding: 14px 20px;
  min-width: 192px;
  border-radius: 10px;
}
.btn-infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .btn-join {
    margin-right: 18px;
  }
  .icon-mic {
    margin-right: 15px;
  }
  span {
    font-size: 18px;
    color: $color_45;
  }
}
.call-icons {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  position: relative;
  .call-items {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    .call-item {
      display: inline-block;
      text-align: center;
      margin-right: 15px;
      display: inline-block;
      text-align: center;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
        margin-right: 0;
      }
      a {
        color: $color_44;
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.07);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 60px;
        height: 60px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        font-size: 20px;
        white-space: nowrap;
        color: $color_54;
        border: 1px solid #ddd;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        display: inline-block;
        font-size: 20px;
        &:hover {
          opacity: 0.8;
        }
        i {
          width: 18px;
          height: 18px;
        }
      }
      .mute-video.stop {
        background: #eb4132;
        border: 1px solid #eb4132;
      }
      .mute-bt.stop {
        background: #eb4132;
        border: 1px solid #eb4132;
      }
    }
  }
}
.set-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
}
.vert-icon {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  color: $color_4;
  &:hover {
    opacity: 0.8;
  }
}
a.vert-icon {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  color: $color_4;
  &:hover {
    opacity: 0.8;
  }
}
.voice-sec1 {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}
.voice-sec {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}
.voice-icon {
  width: 40px;
  height: 40px;
  background: #31aa52;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: $color_44;
  &:hover {
    opacity: 0.8;
  }
}
a.voice-icon {
  width: 40px;
  height: 40px;
  background: #31aa52;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: $color_44;
  &:hover {
    opacity: 0.8;
  }
}
.meet-icons {
  padding: 40px 0px 20px;
  margin-top: 40px;
  border-top: 2px solid rgba(46, 55, 164, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  .meet-items {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    .meet-item {
      display: inline-block;
      text-align: center;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      > a {
        background: rgba(51, 52, 72, 0.05);
        border-radius: 12px;
        color: $color_46;
        border-radius: 8px;
        width: 50px;
        height: 50px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        font-size: 20px;
        &:hover {
          img {
            filter: invert(1) brightness(100);
          }
          opacity: 0.8;
          background: #2e37a4;
        }
      }
      > a.showChatList.active {
        background: #2e37a4;
      }
      > a.showInviteList.active {
        background: #2e37a4;
      }
      > a.hand-raise.active {
        background: #2e37a4;
      }
      > a.mute-bt.stop {
        background: #2e37a4;
      }
      > a.mute-video.stop {
        background: #2e37a4;
        .feather-video-off {
          filter: invert(1) brightness(100);
        }
      }
      > a.show {
        background: #0074e0;
      }
    }
  }
}
.action-items {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  .action-item {
    display: inline-block;
    text-align: center;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    a {
      &:hover {
        opacity: 0.8;
        background: #2e37a4;
      }
      width: 80px;
      height: 50px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-size: 20px;
      box-shadow: 0px 15px 15px rgba(229, 229, 229, 0.32);
      border-radius: 10px;
      color: $color_44;
    }
    a.record-icon.active {
      background: #2e37a4;
    }
    a.share-icon {
      background: #eb4132;
    }
    a.callend-icon {
      background: #0074e0;
    }
    a.record-icon {
      background: #31aa52;
    }
  }
}
.end-calls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.action-icons {
  text-align: center;
}
.part-name {
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: left;
  h4 {
    font-weight: 600;
    font-size: 10px;
    color: $color_44;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #35446f;
    opacity: 0.75;
    backdrop-filter: blur(2.5px);
    border-radius: 8px;
    padding: 10px;
    display: inline-block;
  }
}
.more-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  a {
    color: $color_44;
    background: #35446f;
    opacity: 0.75;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:first-child {
      padding-left: 0;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.single-user {
  .more-icon {
    a {
      width: 30px;
      height: 30px;
    }
  }
}
.meeting {
  .part-name {
    top: inherit;
    left: 15px;
    bottom: 15px;
  }
  .more-icon {
    top: 15px;
    right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  width: inherit;
  max-width: inherit;
  .join-video {
    margin: 0 5px 10px;
    width: 100%;
    height: 309px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      content: "";
      border-radius: 10px;
      opacity: 0;
      transition: opacity 0.3s linear;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
  .join-contents.vertical {
    .join-video.user-active {
      flex: 1 0 100%;
    }
    .join-video.single-user {
      flex: 0;
    }
    .join-video {
      width: auto;
    }
  }
}
.meeting-wrapper {
  width: inherit;
  max-width: inherit;
}
.show-chat-list {
  transition: all 0.8s ease;
  .user-sidebar-right {
    transition: all 0.8s ease;
  }
}
.show-invite-list {
  transition: all 0.8s ease;
  .invite-sidebar-right {
    transition: all 0.8s ease;
  }
}
.user-sidebar-right {
  background: #dedede;
  border-radius: 10px;
  width: 410px;
  position: absolute;
  top: 105px;
  bottom: 117px;
  right: 0;
  margin-right: -410px;
  transition: all 0.8s ease;
  overflow: hidden;
  font-size: 13px;
  z-index: 3;
}
.invite-sidebar-right {
  background: #dedede;
  border-radius: 10px;
  width: 410px;
  position: absolute;
  top: 105px;
  bottom: 117px;
  right: 0;
  margin-right: -410px;
  transition: all 0.8s ease;
  overflow: hidden;
  font-size: 13px;
  z-index: 3;
}
.join-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  overflow: hidden;
}
.join-contents.grid-view {
  align-content: center;
}
.ripple {
  width: 114px;
  height: 114px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.multigrid-view {
  .text-box {
    height: 61px;
    width: 61px;
  }
  .ripple {
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .join-video.single-user {
    width: calc(20% - 10px);
    flex-grow: 0;
  }
}
.join-video.user-active {
  background: #415b6a;
  border-radius: 10px;
}
.join-video.single-user {
  width: 23%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0 5px 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  height: auto;
  background: #415b6a;
  border-radius: 10px;
  padding: 0;
  img {
    height: auto;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s linear;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    .overlay-icon {
      opacity: 1;
    }
  }
}
.text-avatar {
  h4 {
    color: $color_44;
  }
  color: $color_44;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-box {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-color: $background-color_12;
  font-size: 36px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.settings-menu {
  background: #e4eeff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 0 15px;
  margin-bottom: 30px;
  li {
    a {
      padding: 12px 0;
      border-bottom: 1px solid #ffffff;
      width: auto;
      height: auto;
      background: #e4eeff;
      font-weight: 600;
      font-size: 14px;
      color: $color_4;
      &:focus {
        color: $color_4;
        background-color: $background-color_27;
      }
      &:hover {
        color: $color_4;
        background-color: $background-color_27;
      }
    }
    &:last-child {
      a {
        border: 0;
      }
    }
  }
  .user-img {
    font-size: 18px;
    img {
      width: 36px;
      border-radius: 10px;
    }
  }
  ul {
    display: block;
    padding: 0;
    list-style: none;
    li {
      a.active {
        color: $color_17 !important;
        border: 0;
      }
      margin-bottom: 15px;
      a {
        color: $color_63;
        padding: 0;
        border: 0 !important;
        display: inline-block;
      }
    }
  }
}
.settings-menu.setting-list-menu {
  li {
    a {
      background: transparent;
    }
  }
}
.overlay-icon {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 42px;
  left: 15px;
  opacity: 0;
  transition: opacity 0.3s linear;
  a {
    color: $color_44;
    padding: 3px 0;
    font-size: 14px;
    text-align: center;
    &:hover {
      opacity: 0.8;
    }
  }
}
.more-icon.mic-view {
  .mic-off {
    display: block;
  }
}
.grid-view {
  .join-video.single-user {
    width: 48%;
  }
}
.join-contents.multigrid-view {
  max-width: 100%;
  padding: 25px 100px 0;
  justify-content: center;
}
.meeting-list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.user-active {
  .more-icon {
    .handraise-on.active {
      display: block;
      animation: mymove 5s;
      .meeting {
        .join-video.user-active {
          &::before {
            animation: mymove 5s infinite;
            opacity: 1;
          }
        }
      }
    }
  }
}
.join-contents.vertical-view {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-content: center;
  max-width: 1300px;
  .join-video.user-active {
    flex: 1;
    margin-bottom: 5px;
  }
  .join-video.single-user {
    width: 97%;
    flex-grow: 1;
  }
}
.join-contents.vertical {
  flex-direction: column;
  height: 200px;
}
.ptro-holder-wrapper {
  top: 80px !important;
  position: relative !important;
  height: auto !important;
  bottom: 300px;
  z-index: 4;
}
.chat-footer {
  .btn {
    &:hover {
      background: #0066c5;
      color: $color_44;
    }
  }
}
.person-view {
  width: 40px;
  height: 40px;
  background: #415b6a;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 16px;
  color: $color_44;
  font-weight: 700;
  font-size: 14px;
}
.end-call-chat {
  .btn-primary {
    min-width: 136px;
    background: #fe3f51;
    border-radius: 8px;
    margin: 0px 20px;
    padding: 12px 15px;
    font-size: 16px;
  }
}
.compose-mail {
  .btn-primary {
    font-size: 14px;
    font-weight: 500;
    min-width: 100%;
    padding: 10px 15px;
    margin-bottom: 30px;
  }
  h3 {
    color: $color_1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
.email-menu-blk {
  ul {
    padding: 0;
    li {
      list-style: none;
      a {
        color: $color_23;
        font-size: 15px;
        font-weight: 500;
        padding: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 5px;
        &:hover {
          background: rgba(51, 52, 72, 0.05);
          color: $color_1;
        }
      }
      span {
        font-size: 14px;
        margin-left: auto;
        width: 45px;
        height: 26px;
      }
    }
    li.active {
      a {
        background: rgba(51, 52, 72, 0.05);
        color: $color_1;
      }
      span {
        background: #00d3c7;
        border-radius: 20px;
        color: $color_16;
        font-weight: 600;
      }
    }
  }
}
.sub-mails {
  padding: 0;
  position: absolute;
  top: 13px;
  right: 20px;
  li {
    padding: 0px 5px;
    color: $color_1;
    font-size: 14px;
    font-weight: 600;
  }
}
.summer-mail {
  .card {
    .card-header {
      background: rgba(51, 52, 72, 0.05);
      border-radius: 10px;
    }
  }
  .note-editor.note-frame {
    border: 2px solid rgba(46, 55, 164, 0.1);
    border-radius: 10px;
  }
}
.label-blk {
  margin-bottom: 20px;
  h4 {
    margin-bottom: 0;
    color: $color_1;
    font-size: 16px;
    font-weight: 600;
  }
}
.label-add-list {
  li {
    .add-list-btn {
      width: 30px;
      height: 30px;
      background: rgba(46, 55, 164, 0.05);
      border-radius: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      &:hover {
        background: #234ce3;
        color: $color_16;
      }
    }
  }
}
.top-action-right {
  ul {
    li {
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.top-liv-search.top-action-search {
  .form-control {
    background: rgba(51, 52, 72, 0.05);
    border-radius: 12px;
    border: 0;
  }
}
.top-inbox-blk {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  .dropdown-menu {
    text-align: center;
    padding: 0;
    border: 0;
    background: linear-gradient(
        51.64deg,
        rgba(255, 255, 255, 0) 34.2%,
        rgba(255, 255, 255, 0.5) 97.35%,
        rgba(255, 255, 255, 0) 160.66%
      ),
      rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 9px #e1ecff,
      inset 0px 0px 15px rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    .dropdown-item {
      border-bottom: 1px solid #ebebf6;
      font-size: 14px;
      font-weight: 600;
      color: $color_1;
      padding: 10px;
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}
.top-recheck {
  .custom_check {
    input {
      position: relative;
    }
  }
}
.top-check {
  .form-check-input {
    border: 2px solid rgba(51, 52, 72, 0.2);
    border-radius: 4px;
    width: 18px;
    height: 18px;
    &:checked[type="checkbox"] {
      background-color: $background-color_28;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.form-check-input {
  &:checked {
    background-color: $background-color_8;
    border-color: $border-color_5;
  }
}
.select-gender {
  .form-check-input {
    &:focus {
      box-shadow: none;
    }
    width: 12px;
    height: 12px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .gen-label {
    margin-bottom: 5px;
    color: $color_1;
    font-size: 12px;
    font-weight: 500;
  }
  .form-check-label {
    color: $color_23;
    font-weight: 500;
    font-size: 14px;
  }
}
.email-content {
  .table-inbox {
    tr {
      background: #fff;
      border-radius: 5px;
      td {
        vertical-align: middle;
        padding: 15px;
        color: $color_47;
        white-space: nowrap;
      }
    }
  }
}
.email-img {
  img {
    border-radius: 50px;
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}
.send-user {
  h4 {
    color: $color_1;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 5px;
      margin-left: 10px;
      font-size: 10px;
      border-radius: 8px;
    }
  }
  p {
    color: $color_23;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    span {
      color: $color_1;
      font-weight: 600;
    }
  }
  .email-market {
    background: rgba(0, 211, 199, 0.15);
    color: $color_24;
  }
}
.email-img-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.email-office {
  background: rgba(13, 191, 10, 0.15);
  color: $color_48;
}
.email-bills {
  background: rgba(254, 63, 81, 0.15);
  color: $color_49;
}
.email-work {
  background: rgba(255, 182, 39, 0.15);
  color: $color_50;
}
.mail-send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  .btn-primary {
    margin-bottom: 0;
    min-width: 111px;
    border-radius: 12px;
  }
}
.mail-reports {
  ul {
    li {
      .ford-angle {
        background: rgba(51, 52, 72, 0.05);
        border-radius: 10px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        width: 40px;
        height: 40px;
        &:hover {
          background: rgb(35 76 227 / 50%);
          img {
            filter: invert(1) brightness(100);
          }
          i {
            filter: invert(1) brightness(100);
          }
        }
      }
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
      .ford-angle.star-bg {
        background: transparent;
        margin-right: 0;
      }
    }
  }
}
.mail-view-list {
  margin-bottom: 25px;
  li {
    margin-right: 10px;
  }
}
.mailview-inner {
  p {
    color: $color_23;
    font-size: 13px;
    font-weight: 600;
  }
}
.thank-mail {
  margin-bottom: 40px;
  h5 {
    color: $color_42;
    font-size: 14px;
    margin-bottom: 0;
  }
  h4 {
    color: $color_1;
    font-size: 16px;
    font-weight: 600;
  }
}
.forward-send {
  .btn-primary {
    padding: 10px 15px;
    padding: 7px 23px;
    margin-bottom: 0;
    border: 2px solid transparent;
    font-size: 14px;
  }
  .forwrd-btn {
    background: transparent;
    border: 2px solid #234ce3;
    color: $color_51;
    font-weight: 600;
    &:hover {
      background: #234ce3;
      border: 2px solid #234ce3;
      color: $color_16;
      img {
        filter: invert(1) brightness(100);
      }
    }
  }
}
.custom-file-upload {
  background: linear-gradient(
      51.64deg,
      rgba(255, 255, 255, 0) 34.2%,
      rgba(255, 255, 255, 0.2) 97.35%,
      rgba(255, 255, 255, 0) 160.66%
    ),
    rgba(255, 255, 255, 0.2);
  border: 2px solid #ffffff;
  border-radius: 8px;
  display: inline-flex;
  transition: border-color 0.3s;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  margin-top: 0;
  height: 53px;
  color: $color_4;
  position: relative;
  input {
    display: none;
  }
  .file-upload-text {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    padding-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.change-user {
  padding: 10px 18px;
  background: #6586bf;
  border-radius: 8px;
  font-size: 16px;
  color: $color_44;
  position: absolute;
  right: 0;
  margin: 2px;
  min-width: 177px;
  text-align: center;
}
.sender-img {
  img {
    width: 48px;
    height: 48px;
  }
}
.send-user.send-user-name {
  h4 {
    font-size: 15px;
    span {
      padding: 5px 15px;
    }
  }
}
.ford-angle.star-bg {
  i {
    color: $color_50;
  }
}
.mail-attach {
  .msg-sub-list {
    li {
      max-width: 100%;
      margin-bottom: 0;
      margin-right: 10px;
      i {
        color: $color_49;
      }
    }
  }
  margin-bottom: 40px;
  h2 {
    color: $color_42;
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    a {
      color: $color_3;
      font-size: 14px;
    }
  }
}
.view-content-bottom {
  border-bottom: 2px solid #ffffff;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.setting-title {
  p {
    margin-bottom: 0;
  }
}
.form-focus {
  display: flex;
  height: 50px;
  position: relative;
  .focus-label {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate3d(0, 22px, 0) scale(1);
    -ms-transform: translate3d(0, 22px, 0) scale(1);
    -o-transform: translate3d(0, 22px, 0) scale(1);
    transform: translate3d(0, 22px, 0) scale(1);
    transform-origin: left top;
    transition: 240ms;
    left: 12px;
    top: -8px;
    z-index: 1;
    color: $color_20;
  }
  .form-control {
    height: 50px;
    padding: 21px 12px 6px;
    &::-webkit-input-placeholder {
      color: $color_52;
      transition: 240ms;
    }
    &:focus {
      &::-webkit-input-placeholder {
        transition: none;
      }
    }
  }
  .select2-container {
    .select2-selection--single {
      border: 1px solid #ccc;
      height: 50px;
      border-radius: 0;
      .select2-selection__rendered {
        padding-right: 30px;
        padding-left: 12px;
        padding-top: 10px;
      }
    }
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__arrow {
        height: 48px;
        right: 7px;
        b {
          border-color: $border-color_8;
          border-style: solid;
          border-width: 6px 6px 0;
          height: 0;
          left: 50%;
          margin-left: -10px;
          margin-top: -2px;
          position: absolute;
          top: 50%;
          width: 0;
        }
      }
      .select2-selection__rendered {
        color: $color_34;
        font-size: 14px;
        font-weight: normal;
        line-height: 38px;
      }
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: $background-color_29;
    }
  }
  .select2-container--default.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: $border-color_9;
          border-width: 0 6px 6px;
        }
      }
    }
  }
}
.form-focus.focused {
  .focus-label {
    opacity: 1;
    font-weight: 300;
    top: -14px;
    font-size: 12px;
    z-index: 1;
  }
  .form-control {
    &::-webkit-input-placeholder {
      color: $color_53;
    }
  }
}
.file-size {
  color: $color_20;
}
.filter-row {
  .btn {
    border-radius: 0;
    min-height: 50px;
    padding: 12px;
    text-transform: uppercase;
  }
}
.form-focus.select-focus {
  .focus-label {
    opacity: 1;
    font-weight: 300;
    top: -20px;
    font-size: 12px;
    z-index: 1;
  }
}
.action-icon {
  color: $color_54;
  font-size: 14px;
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
  width: 31px;
  height: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-left: auto;
}
.profile-widget {
  background-color: $background-color_1;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  .user-name {
    > a {
      color: $color_13;
    }
  }
}
.dropdown.profile-action {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
}
.profile-action {
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}
.profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
  .avatar {
    font-size: 24px;
    height: 80px;
    line-height: 80px;
    margin: 0;
    width: 80px;
  }
}
.mobile-no {
  > a {
    color: $color_54;
    display: inline-block;
  }
}
.staff-mail {
  > a {
    color: $color_54;
    display: inline-block;
    margin-top: 5px;
  }
}
.staff-action-btns {
  margin-top: 10px;
}
.staff-id {
  margin-top: 5px;
}
.view-icons {
  float: right;
  margin-right: 10px;
  .btn {
    color: $color_20;
    font-size: 18px;
    margin-right: 5px;
    padding: 4px;
  }
  .btn.active {
    color: $color_13;
  }
}
.attent-status {
  background: rgba(0, 211, 199, 0.15);
  border-radius: 6px;
  width: 26px;
  height: 26px;
  color: $color_24;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.present-table {
  background: rgba(0, 211, 199, 0.15);
  color: $color_24;
}
.absent-table {
  background: rgba(255, 54, 103, 0.1);
  color: $color_28;
}
.month-table {
  h5 {
    font-weight: 500;
    font-size: 14px;
  }
}
.attent-table {
  thead {
    tr {
      th {
        &:first-child {
          color: $color_1;
        }
        font-weight: 600;
        font-size: 14px;
        color: $color_3;
      }
    }
  }
  .week-days {
    color: $color_27;
  }
}
.card-box.profile-header {
  margin: 0;
}
.profile-details {
  text-align: center;
}
.personal-info {
  li {
    .title {
      color: $color_56;
      float: left;
      font-weight: 500;
      margin-right: 30px;
      width: 30%;
      font-size: 14px;
    }
    .text {
      color: $color_57;
      display: block;
      overflow: hidden;
      font-size: 14px;
    }
    margin-bottom: 10px;
  }
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  .text {
    > a {
      color: $color_17;
      font-size: 14px;
    }
  }
}
.profile-view {
  .profile-img-wrap {
    height: 150px;
    width: 150px;
  }
  .profile-img {
    width: 150px;
    height: 150px;
    .avatar {
      font-size: 24px;
      height: 150px;
      line-height: 150px;
      margin: 0;
      width: 150px;
    }
  }
  .profile-basic {
    margin-left: 170px;
  }
}
.staff-msg {
  margin-top: 30px;
}
.experience-box {
  position: relative;
}
.experience-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  &:before {
    background: #ddd;
    bottom: 0;
    content: "";
    left: 8px;
    position: absolute;
    top: 8px;
    width: 2px;
  }
  > li {
    position: relative;
    &:last-child {
      .experience-content {
        margin-bottom: 0;
      }
    }
    .experience-user {
      background: #fff;
      height: 10px;
      left: 4px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 4px;
      width: 10px;
    }
    .experience-content {
      background-color: $background-color_1;
      margin: 0 0 20px 40px;
      padding: 0;
      position: relative;
      .timeline-content {
        color: $color_7;
        a.name {
          color: $color_58;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
    .time {
      color: $color_59;
      display: block;
      font-size: 12px;
      line-height: 1.35;
    }
  }
}
.experience-user {
  .avatar {
    height: 32px;
    line-height: 32px;
    margin: 0;
    width: 32px;
  }
}
.before-circle {
  background-color: $background-color_30;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.skills {
  > span {
    border: 1px solid #ccc;
    border-radius: 500px;
    display: block;
    margin-bottom: 10px;
    padding: 6px 12px;
    text-align: center;
  }
}
.profile-info-left {
  border-right: 2px dashed #ccc;
  h3 {
    font-size: 20px;
  }
  .staff-id {
    font-size: 15px;
  }
}
.profile-tabs {
  .nav-tabs {
    background-color: $background-color_1;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
}
.alert {
  font-size: 14px;
}
.notifications {
  padding: 0;
  .drop-scroll {
    height: 290px;
    width: 400px;
  }
  .notification-heading {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.45;
    color: $color_58;
  }
  .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: $color_59;
  }
  ul.notification-list {
    > li {
      margin-top: 0;
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        padding: 12px;
        border-radius: 2px;
        &:hover {
          background-color: $background-color_31;
        }
      }
    }
  }
}
.notifications.msg-noti {
  .drop-scroll {
    width: 310px;
  }
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
  .list-group-item {
    padding: 15px;
  }
  .status-toggle {
    float: right;
  }
}
.drop-scroll {
  overflow-y: scroll;
  position: relative;
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}
.topnav-dropdown-footer {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  border-top: 1px solid #eee;
  a {
    display: block;
    text-align: center;
    color: $color_13;
  }
}
.noti-details {
  color: $color_60;
  margin: 0;
}
.noti-time {
  margin: 0;
}
.noti-title {
  color: $color_13;
}
.roles-menu {
  margin-top: 20px;
  > ul {
    background-color: $background-color_1;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      display: block;
      position: relative;
      > a {
        border-left: 3px solid transparent;
        color: $color_13;
        display: block;
        padding: 10px 15px;
        position: relative;
        &:hover {
          border-color: $border-color_4;
          background-color: $background-color_13;
        }
      }
      &:hover {
        .role-action {
          display: block;
        }
      }
    }
    > li.active {
      > a {
        border-color: $border-color_4;
        color: $color_6;
      }
    }
  }
}
.material-switch {
  > input[type="checkbox"] {
    display: none;
    &:checked {
      + {
        label {
          &:before {
            background: inherit;
            opacity: 0.5;
          }
          &:after {
            background: inherit;
            left: 20px;
          }
        }
      }
    }
  }
  > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
    &:before {
      background: rgb(0, 0, 0);
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      content: "";
      height: 16px;
      margin-top: -8px;
      position: absolute;
      opacity: 0.3;
      transition: all 0.4s ease-in-out;
      width: 40px;
    }
    &:after {
      background: rgb(255, 255, 255);
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      content: "";
      height: 24px;
      left: -4px;
      margin-top: -8px;
      position: absolute;
      top: -4px;
      transition: all 0.3s ease-in-out;
      width: 24px;
    }
  }
}
.role-action {
  display: none;
  float: right;
  position: absolute;
  right: 8px;
  top: 8px;
}
.slide-nav {
  .sidebar {
    margin-left: 0;
  }
}
.action-circle {
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  .material-icons {
    color: $color_61;
    font-size: 18px;
    vertical-align: -3px;
  }
}
.action-circle.completed {
  background: #35ba67;
  border: 1px solid #2fa65c;
  .material-icons {
    color: $color_16;
  }
}
.action-circle.large {
  height: 24px;
  width: 24px;
  .material-icons {
    font-size: 16px;
    vertical-align: -4px;
  }
}
.sidebar-overlay {
  display: none;
  position: fixed;
  z-index: 90;
  height: 100%;
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 99;
  background-color: $background-color_32;
  overflow: hidden;
}
.sidebar-overlay.opened {
  display: block;
}
.menu-opened {
  .sidebar-overlay {
    display: block;
  }
  .message-view.chat-view {
    width: 100%;
  }
}
.sidebar-menu.opened {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  margin-left: 0;
}
html.menu-opened {
  overflow: hidden;
  .main-wrapper {
    overflow: hidden;
  }
}
.chat-profile-view.opened {
  margin-right: 0;
}
.fixed-header {
  .custom-menu {
    margin: 1px 0 0;
  }
}
.notification-box {
  bottom: 0;
  margin-right: -300px;
  position: fixed;
  right: 0;
  top: 70px;
  transition: all 0.4s ease 0s;
  width: 300px;
  z-index: 101;
  // border-left: 1px solid #ddd;
}
.open-msg-box {
  .notification-box {
    margin-right: 0;
  }
}
.msg-sidebar {
  background-color: $background-color_1;
}
.list-box {
  list-style: none;
  padding-left: 0;
}
.list-item.new-message {
  background-color: $background-color_12;
}
.list-item {
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  position: relative;
}
.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}
.files-icon {
  background-color: $background-color_33;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
  i {
    color: $color_54;
    font-size: 20px;
  }
}
.list-body {
  padding: 0 0 0 50px;
}
.message-author {
  color: $color_1;
  float: left;
  font-weight: bold;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}
.message-time {
  color: $color_20;
  float: right;
  font-size: 11px;
}
.message-content {
  color: $color_13;
  font-size: 11px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.invoice-details {
  float: right;
  text-align: right;
  h3 {
    font-size: 14px;
    font-weight: 700;
  }
  h4 {
    font-size: 14px;
    font-weight: 600;
    span {
      font-weight: 500;
      color: $color_30;
    }
  }
}
.invoice-payment-details {
  > li {
    span {
      float: right;
      text-align: right;
    }
  }
}
.attach-files {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    img {
      width: 50px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.file-remove {
  color: $color_62;
  position: absolute;
  right: -6px;
  top: -7px;
}
.inv-logo {
  height: auto;
  margin-bottom: 20px;
  max-height: 100px;
  width: auto;
}
.form-amt {
  border: 1px solid #fff;
}
.page-title-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
}
.invoices-links {
  &:last-child {
    margin-right: 0px;
    margin-right: 0px;
  }
  color: $color_63;
  margin-right: 20px;
  color: $color_63;
  margin-right: 20px;
}
.invoice-inner-datepic {
  border: 0;
  padding-left: 0;
  border: 0;
  padding-left: 0;
  border: 0;
  padding-left: 0;
}
.invoices-form {
  .inovices-month-info {
    .form-control {
      height: 42px;
    }
  }
  .multipleSelection {
    .selectBox {
      border: 2px solid #2e37a4;
      border-radius: 6px;
    }
  }
  .form-control {
    height: 50px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
  }
}
.app-listing {
  padding: 0;
  margin: 0 -15px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  > li {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.multipleSelection {
  position: relative;
  margin-bottom: 20px;
  position: relative;
  margin-bottom: 20px;
  .selectBox {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 11px 10px;
    font-weight: 400;
    background: #ffffff;
    border: 2px solid rgba(46, 55, 164, 0.1);
    border-radius: 10px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  #checkBoxes {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
    border-radius: 6px;
    &::before {
      border: 7px solid #f2ecff;
      border-color: $border-color_11;
      box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
      content: "";
      left: 30px;
      position: absolute;
      top: 0;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
  #checkBoxes-one {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
    border-radius: 6px;
  }
}
.SortBy {
  .selectBoxes {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 11px 10px;
    font-weight: 400;
    background: #ffffff;
    border: 2px solid rgba(46, 55, 164, 0.1);
    border-radius: 10px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  #checkBox {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
    border-radius: 6px;
    &::before {
      border: 7px solid #f2ecff;
      border-color: $border-color_11;
      box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
      content: "";
      left: 30px;
      position: absolute;
      top: 0;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}
.selectBox {
  p {
    color: $color_63;
    font-weight: 500;
    font-size: 14px;
    .select-icon {
      color: $color_17;
      width: 20px;
      height: 20px;
      font-size: 20px;
      top: 2px;
      position: relative;
    }
  }
}
.checkbox-title {
  font-weight: 600;
  font-size: 15px;
  color: $color_17;
  margin-bottom: 14px;
}
#checkBoxes {
  .form-custom {
    margin-bottom: 14px;
    margin-bottom: 14px;
  }
  .btn {
    font-size: 13px;
    font-weight: 500;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
  }
  .btn-grey {
    background: #55ddff30;
    color: $color_63;
    margin-top: 10px;
    border: none;
  }
  .date-picker {
    .form-custom {
      .form-control {
        padding-left: 10px;
      }
    }
  }
}
.selectBox-cont {
  height: 220px;
  overflow-y: auto;
  padding-right: 5px;
}
.custom_check {
  color: $color_63;
  display: inline-block;
  position: relative;
  font-size: 13px;
  margin-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ {
        .checkmark {
          &:after {
            display: block;
          }
          background-color: $background-color_11;
          border-color: $border-color_1;
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #c4c4c4;
    background-color: $background-color_17;
    border-radius: 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &::after {
      content: "\f00c";
      font-family: $font-family_5;
      font-weight: 900;
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      color: $color_64;
      font-size: 9px;
    }
  }
}
#checkBox {
  .btn {
    font-size: 13px;
    font-weight: 500;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
  }
}
.feather {
  width: 18px;
  height: 18px;
}
.upload-icon {
  width: 13px;
  height: 13px;
}
.report-btn {
  margin-bottom: 20px;
  .btn {
    font-weight: 600;
    color: $color_17;
    background: #ffffff;
    border: 2px solid #2e37a4;
    border-radius: 8px;
    width: 100%;
    padding: 11px 0;
    &:hover {
      color: $color_35;
      background: #2e37a4;
      border: 2px solid #2e37a4;
      img {
        filter: invert(0) brightness(100);
      }
    }
  }
}
.selectBox-cont-one {
  overflow-y: inherit;
}
.date-picker {
  width: 50%;
  float: left;
  padding-right: 10px;
}
.form-custom.cal-icon {
  &:after {
    font-size: 14px;
  }
}
.date-list {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      width: 100%;
      padding: 0;
      padding-bottom: 5px;
    }
  }
  .date-btn {
    background: #fff;
    color: $color_63;
    border: 1px solid #e7eafc;
    border-radius: 4px;
    padding: 10px 0 !important;
    width: 100%;
    &:hover {
      background: #00d3c7;
      color: $color_16;
      border: 1px solid #e7eafc;
    }
  }
}
.invoices-tabs-card {
  background: transparent;
  box-shadow: none;
  border: 0;
}
.inovices-card {
  .card-body {
    padding: 15px;
  }
  border-radius: 5px;
}
.invoices-main-tabs {
  border-bottom: 2px solid #f3f3f3;
  padding-bottom: 10px;
}
.invoices-tabs {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      position: relative;
      display: inline-block;
      margin-right: 36px;
      a.active {
        color: $color_17;
        border-bottom: 2px solid #2e37a4;
      }
      a {
        font-weight: 500;
        font-size: 15px;
        color: $color_63;
        padding-bottom: 24px;
      }
    }
  }
  > ul {
    > li {
      > a {
        &:hover {
          color: $color_17;
        }
      }
    }
  }
}
.invoices-settings-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  .btn {
    font-weight: 600;
    color: $color_16;
    background-color: $background-color_11;
    padding: 12px 0;
    min-width: 167px;
    border: 1px solid #2e37a4;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    svg {
      margin-right: 10px;
    }
    &:hover {
      background: #00d3c7;
      border: 1px solid #00d3c7;
    }
  }
}
.invoices-settings-icon {
  color: $color_17;
  margin-right: 20px;
}
.inovices-widget-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  .inovices-amount {
    font-weight: 600;
    font-size: 20px;
    color: $color_17;
  }
}
.inovices-widget-icon {
  img {
    width: 50px;
  }
}
.inovices-all {
  font-weight: 500;
  color: $color_63;
  margin-top: 16px;
  span {
    font-size: 10px;
    margin-left: 15px;
  }
}
table {
  .badge {
    border-radius: 6px;
    display: inline-block;
    font-size: 13px;
    min-width: 105px;
    padding: 8px 20px;
    font-weight: 500;
    text-align: center;
  }
}
.bg-primary-light {
  background-color: $background-color_34 !important;
  color: $color_65 !important;
}
.bg-success-light {
  background-color: $background-color_35 !important;
  color: $color_66 !important;
}
.bg-danger-light {
  background-color: $background-color_36 !important;
  color: $color_22 !important;
}
.invoices-links.active {
  color: $color_17;
}
.invoices-grid-card {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgb(228 228 228 / 25%);
  border-radius: 10px;
  color: $color_63;
  .card-header {
    padding: 20px;
    border: 0;
  }
  .card-body {
    padding: 20px;
    span {
      font-size: 14px;
    }
  }
  .card-footer {
    padding: 20px;
    border: 0;
    .badge {
      border-radius: 6px;
      font-size: 13px;
      font-weight: 400;
      padding: 7px 18px;
    }
  }
  .bg-success-dark {
    background: #008f64;
    background: #008f64;
  }
  .bg-danger-dark {
    background: #ff0000;
  }
  .bg-secondary-dark {
    background: #009efb;
  }
  .bg-primary-dark {
    background: #2196f3;
  }
  .text-sm {
    font-size: 14px;
  }
}
.invoice-grid-link {
  color: $color_63;
  font-size: 14px;
}
.card-middle {
  padding: 20px;
  background: #55ddff30;
}
.card-middle-avatar {
  align-items: center;
  display: inline-flex;
  font-size: 15px;
  margin: 0;
  a {
    color: $color_63;
  }
}
.card-footer {
  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
.preclincs-title {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}
a.invoices-settings-icon {
  &:hover {
    color: $color_14;
  }
}
a.btn.delete-invoice-btn {
  &:hover {
    background: #474d52;
  }
}
a.btn.save-invoice-btn {
  &:hover {
    background: #00d3c7;
  }
}
a.invoices-preview-link {
  &:hover {
    color: $color_14;
  }
}
li.breadcrumb-item.invoices-breadcrumb-item {
  &:hover {
    > a {
      color: $color_14;
      i {
        background: #00d3c7;
      }
    }
  }
}
.invoice-info {
  > strong {
    > a {
      &:hover {
        color: $color_14;
      }
    }
  }
  margin-bottom: 20px;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
    color: $color_30;
    font-size: 14px;
  }
  h5 {
    font-size: 16px;
    font-weight: 500;
  }
}
.invoice-inner-head {
  span {
    a {
      &:hover {
        color: $color_14;
      }
      font-weight: 500;
      color: $color_17;
    }
    color: $color_63;
    font-size: 14px;
  }
  padding: 26px;
  border-bottom: 1px solid #e5e5e5;
}
.field-box {
  > a {
    &:hover {
      background: #00d3c7;
    }
  }
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  p {
    font-weight: 500;
    font-size: 14px;
    color: $color_63;
    margin-bottom: 10px;
  }
  .btn {
    min-width: 266px;
    border-radius: 4px;
    padding: 30px 0;
  }
}
.invoice-total-inner {
  a {
    &:hover {
      color: $color_14;
    }
  }
  padding: 15px;
  input[type="checkbox"] {
    display: none;
  }
}
h2.card-middle-avatar {
  &:hover {
    > a {
      color: $color_14;
    }
  }
}
.upload-sign {
  button {
    &:hover {
      background: #00d3c7;
    }
  }
  float: right;
  margin-top: 20px;
  .btn {
    border-radius: 8px;
    min-width: 198px;
    padding: 10px 0;
  }
}
.payment-details {
  &:hover {
    background: #00d3c7;
  }
  width: 266px;
  border-radius: 4px;
  padding: 15px;
  background: #2e37a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 266px;
  border-radius: 4px;
  padding: 15px;
  background: #2e37a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: $color_16;
    max-width: 140px;
    font-weight: 400;
    margin-bottom: 0;
    color: $color_16;
    max-width: 140px;
    font-weight: 400;
    margin-bottom: 0;
  }
  a {
    font-size: 26px;
    color: $color_16;
  }
}
.invoices-upload-btn {
  &:hover {
    .upload {
      background-color: $background-color_8;
    }
  }
  border: 1px solid #dee2e6;
  border-radius: 6px;
  color: $color_40;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 50px;
  .upload {
    position: absolute;
    background: #2e37a4;
    border-radius: 4px;
    right: 6px;
    top: 8px;
    width: 136px;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color_16;
  }
  .hide-input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0;
  }
}
.invoice-setting-btn {
  .cancel-btn {
    &:hover {
      background-color: $background-color_37;
    }
    background: #6c757d;
  }
  button {
    &:last-child {
      &:hover {
        background-color: $background-color_8;
      }
    }
  }
  .btn {
    min-width: 160px;
    padding: 10px 0;
    color: $color_16;
    border-radius: 8px;
    border: 0;
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.settings-btns {
  button {
    &:nth-child(1) {
      &:hover {
        background-color: $background-color_8;
      }
    }
  }
}
.settings-btn {
  &:hover {
    .upload {
      background-color: $background-color_8;
    }
  }
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  color: $color_40;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
  .hide-input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0;
  }
  .upload {
    position: absolute;
    z-index: 11;
    background: #2e37a4;
    border-radius: 5px;
    right: 2px;
    top: 1.5px;
    width: 41px;
    height: 41px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color_16;
  }
}
.nav-tabs.menu-tabs {
  .nav-link {
    &:hover {
      background: #2e37a4;
      color: $color_16;
    }
    position: relative;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    color: $color_16;
    background: rgb(46 55 164 / 53%);
  }
  .nav-item {
    padding-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .nav-item.active {
    .nav-link {
      color: $color_16;
      background: #2e37a4;
    }
  }
}
.form-row.links-cont {
  a {
    &:hover {
      background: #00d3c7;
    }
  }
  button {
    &:hover {
      background: #00d3c7;
    }
  }
  margin-bottom: 0px;
}
a.btn.add-links {
  &:hover {
    background: #00d3c7;
  }
}
.breadcrumb-item {
  a {
    &:hover {
      color: $color_14;
    }
  }
}
.breadcrumb-item.active {
  color: $color_19;
}
.breadcrumb-item + .breadcrumb-item {
  &::before {
    display: none;
  }
  i {
    color: $color_3;
  }
}
.doctor-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto 15px;
  position: relative;
  width: 80px;
  .avatar {
    font-size: 24px;
    height: 80px;
    line-height: 80px;
    margin: 0;
    width: 80px;
  }
}
.doc-prof {
  color: $color_54;
  font-size: 12px;
  margin-bottom: 10px;
}
.doctor-name {
  margin: 0;
  a {
    color: $color_13;
  }
}
.see-all {
  margin-top: 30px;
  text-align: center;
  .see-all-btn {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: $color_54;
    display: inline-block;
    padding: 10px 20px;
  }
}
.comman-table.custom-table {
  thead {
    tr {
      th {
        color: $color_1;
        font-size: 14px;
        font-weight: 500;
        font-weight: 600;
      }
    }
  }
  tbody {
    tr {
      td {
        color: $color_1;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.show-entire {
  .dataTables_length {
    display: none;
  }
}
.doctor-table-blk {
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.doctor-search-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .top-nav-search {
    margin-left: 15px;
    position: relative;
    form {
      margin: 0px;
      width: 100%;
    }
  }
}
.add-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .btn-primary {
    background: rgba(46, 55, 164, 0.05);
    border-radius: 12px;
    color: $color_3;
    width: 40px;
    height: 40px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:hover {
      border: 0;
      background: #2e37a4;
      transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      img {
        filter: invert(1) brightness(100);
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
      }
    }
  }
}
.table.comman-table {
  > thead {
    > tr {
      > th {
        padding: 15px 15px;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        padding: 15px 15px;
      }
    }
  }
}
.card-table {
  .card-body {
    padding: 0;
  }
  .dataTables_info {
    padding-left: 15px;
    font-size: 14px;
  }
}
.page-table-header {
  padding: 15px 15px 0px 15px;
}
.profile-image {
  a {
    color: $color_1;
    &:hover {
      color: $color_17;
    }
  }
}
.profile-upload {
  display: flex;
}
.upload-img {
  margin-right: 10px;
  img {
    border-radius: 4px;
    height: 40px;
    width: 40px;
  }
}
.upload-input {
  width: 100%;
}
.gen-label {
  display: block;
  margin-bottom: 18px;
}
.submit-btn {
  border-radius: 50px;
  color: $color_16;
  font-size: 15px;
  font-weight: 500;
  min-width: 200px;
  padding: 8px 20px;
  text-transform: uppercase;
}
.delete-modal {
  .modal-body {
    .btn {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      width: 90px;
      color: $color_13;
    }
    .btn.btn-danger {
      color: $color_16;
    }
    > h3 {
      font-size: 16px;
      font-weight: bold;
      margin: 15px 0 0;
    }
  }
}
.custom-badge {
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
  min-width: 115px;
  padding: 5px 15px;
  text-align: center;
  border: 0;
}
.status-red {
  background-color: $background-color_24;
  color: $color_33;
}
a.status-red {
  background-color: $background-color_24;
  color: $color_33;
}
.status-green {
  background: rgba(0, 211, 199, 0.1);
  color: $color_24;
}
a.status-green {
  background: rgba(0, 211, 199, 0.1);
  color: $color_24;
}
.status-orange {
  background-color: $background-color_38;
  color: $color_67;
}
a.status-orange {
  background-color: $background-color_38;
  color: $color_67;
}
.status-blue {
  background-color: $background-color_39;
  color: $color_68;
}
a.status-blue {
  background-color: $background-color_39;
  color: $color_68;
}
.status-purple {
  background-color: $background-color_40;
  color: $color_69;
}
a.status-purple {
  background-color: $background-color_40;
  color: $color_69;
}
.status-pink {
  background-color: $background-color_41;
  color: $color_70;
}
a.status-pink {
  background-color: $background-color_41;
  color: $color_70;
}
.status-grey {
  background-color: $background-color_30;
  color: $color_71;
}
a.status-grey {
  background-color: $background-color_30;
  color: $color_71;
}
.btn-grey {
  background-color: $background-color_42;
  border: 1px solid #b2b2b2;
}
.modal-backdrop {
  background-color: $background-color_1;
}
.modal-backdrop.show {
  opacity: 0.9;
}
.invoices-view {
  width: 70%;
  float: right;
}
.local-forms.form-group {
  position: relative;
  margin-bottom: 30px;
}
.local-forms {
  label {
    font-size: 12px;
    color: $color_1;
    font-weight: 500;
    position: absolute;
    top: -10px;
    left: 10px;
    background: #fff;
    margin-bottom: 0;
    padding: 0px 5px;
    z-index: 99;
  }
  .select2-container {
    .select2-selection--single {
      height: 45px;
    }
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        line-height: 45px;
      }
      .select2-selection__arrow {
        height: 42px;
      }
    }
  }
}
.form-heading {
  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
.local-top-form {
  position: relative;
  .local-top {
    font-size: 12px;
    color: $color_1;
    font-weight: 500;
    position: absolute;
    top: -10px;
    left: 10px;
    background: #fff;
    margin-bottom: 0;
    padding: 0px 5px;
    z-index: 99;
  }
}
.settings-btn.upload-files-avator {
  .upload {
    width: 148px;
    background: #f4f5fa;
    color: $color_3;
    font-size: 14px;
    font-weight: 500;
    right: -2px;
    top: -2px;
    height: 46px;
  }
  min-height: 45px;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
}
.doctor-submit {
  .btn-primary {
    min-width: 131px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    padding: 10px 15px;
  }
  .cancel-form {
    background: rgba(51, 52, 72, 0.05);
    color: $color_1;
    &:hover {
      background: #2e37a4;
      color: $color_16;
      border: 1px solid #2e37a4;
    }
  }
}
.calendar-icon {
  &:after {
    color: $color_1;
    content: "\f073";
    display: block;
    font-family: $font-family_6;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
}
.top-nav-search.table-search-blk {
  .btn {
    min-height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.doctor-profile-head {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
}
.profile-bg-img {
  img {
    width: 100%;
    border-radius: 10px;
  }
}
.profile-user-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.profile-user-img {
  margin-right: 20px;
  position: relative;
  top: -30px;
  img {
    border-radius: 50%;
    width: 141px;
  }
}
.names-profiles {
  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 3px;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: $color_3;
    margin-bottom: 0;
  }
}
.profile-edit-icon {
  position: absolute;
  border-radius: 50px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  img {
    width: 25px;
  }
}
.follow-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.doctor-follows {
  text-align: center;
  h5 {
    color: $color_23;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.follow-btn-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  .btn-info {
    min-width: 127px;
    font-size: 16px;
    font-weight: 500;
    color: $color_16;
    border-radius: 10px;
    padding: 9px 15px;
  }
  .follow-btns {
    background: #2e37a4;
    border: 1px solid #2e37a4;
    margin-right: 15px;
    &:hover {
      background: #00d3c7;
      border: 1px solid #00d3c7;
    }
  }
  .message-btns {
    background: #00d3c7;
    border: 1px solid #00d3c7;
    &:hover {
      background: #2e37a4;
      border: 1px solid #2e37a4;
    }
  }
}
.personal-activity {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.views-personal {
  margin-left: 10px;
  h5 {
    color: $color_30;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.personal-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  i {
    color: $color_72;
  }
}
.doctor-personals-grp {
  .card {
    border-radius: 10px;
    box-shadow: none;
  }
}
.heading-detail {
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  p {
    color: $color_30;
    font-size: 14px;
    font-weight: 500;
  }
}
.hello-park {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(46, 55, 164, 0.1);
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: $color_30;
  }
}
.skills-head {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  h5 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
    color: $color_30;
  }
  p {
    margin-bottom: 0;
    color: $color_30;
    font-size: 15px;
    font-weight: 500;
  }
}
.skill-statistics {
  margin-bottom: 25px;
  .progress {
    height: 4px;
  }
}
.bg-operations {
  background: #2e37a4;
}
.bg-statistics {
  background: #00d3c7;
}
.bg-haemoglobin {
  background: #ff3667;
}
.bg-endoscopic {
  background: #ffbf42;
}
.bg-visit {
  background: #333548;
}
.setting-form-blk {
  .local-forms {
    label {
      font-size: 12px;
      color: $color_23;
    }
    .form-control {
      &::placeholder {
        color: $color_23;
        font-size: 14px;
      }
    }
  }
}
.about-info {
  h4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    font-size: 18px;
    font-weight: 500;
    i {
      color: $color_73;
      background: rgba(51, 53, 72, 0.05);
      border-radius: 10px;
      width: 31px;
      height: 31px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
  }
  margin-bottom: 25px;
}
.doctor-up-files {
  label {
    font-size: 15px;
    font-weight: 500;
  }
  label.file-upload.image-upbtn {
    background: #444;
    border: 1px solid #444;
    border-radius: 8px;
    color: $color_16;
    padding: 6px 19px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background: #000;
      border: 1px solid #000;
    }
  }
  label.file-upload {
    position: relative;
    overflow: hidden;
    input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }
}
.doctor-up-files.profile-edit-icon {
  label.file-upload.profile-upbtn {
    cursor: pointer;
  }
}
.list-space {
  padding: 0;
  margin: 0;
}
.about-me-list {
  ul {
    li {
      list-style: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      h4 {
        min-width: 50%;
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;
      }
      span {
        min-width: 50%;
        font-size: 14px;
        font-weight: 500;
        color: $color_30;
      }
    }
  }
}
.tab-content-set {
  border-bottom: 4px solid rgba(46, 55, 164, 0.05);
  padding-bottom: 25px;
  margin-bottom: 25px;
  ul {
    li {
      a.active {
        &::after {
          position: absolute;
          content: "";
          background: #2e37a4;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: -29px;
        }
        color: $color_3;
        .set-about-icon {
          background: rgba(46, 55, 164, 0.05);
          img {
            filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg)
              brightness(50%) contrast(340%);
          }
        }
      }
      a {
        &:hover {
          &::after {
            position: absolute;
            content: "";
            background: #2e37a4;
            width: 100%;
            height: 4px;
            left: 0;
            bottom: -29px;
          }
          color: $color_3;
          .set-about-icon {
            background: rgba(46, 55, 164, 0.05);
            img {
              filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg)
                brightness(50%) contrast(340%);
            }
          }
        }
      }
    }
  }
  li {
    a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: $color_30;
      font-size: 15px;
      font-weight: 600;
      position: relative;
    }
    padding-right: 20px;
  }
}
.set-about-icon {
  background: rgba(51, 53, 72, 0.05);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.detail-personal {
  h2 {
    font-size: 15px;
    font-weight: 500;
  }
  h3 {
    font-size: 12px;
    font-weight: 500;
    color: $color_30;
    margin-bottom: 0;
  }
}
.personal-list-out {
  margin-bottom: 30px;
}
.profile-table.custom-table {
  thead {
    tr {
      th {
        font-size: 14px;
        color: $color_32;
        font-weight: 500;
        padding: 10px 0px;
        color: $color_32;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px;
        color: $color_32;
        font-weight: 500;
        padding: 10px 0px;
        color: $color_30;
      }
      box-shadow: none;
    }
  }
}
.profile-check-blk {
  .remember-me {
    margin-bottom: 20px;
  }
  .custom_check {
    input {
      &:checked {
        ~ {
          .checkmark {
            background-color: $background-color_7;
            border-color: $border-color_7;
          }
        }
      }
    }
  }
  .form-heading {
    h4 {
      font-size: 16px;
    }
  }
}
.patient-profile-table {
  tbody {
    tr {
      td {
        &:nth-child(4) {
          color: $color_23;
        }
      }
    }
  }
}
.staff-search-table {
  padding: 0px 15px;
  margin-top: 30px;
}
.submit-list-form {
  width: 100%;
}
.status-staff {
  background: #ffffff;
  border: 1px solid rgba(46, 55, 164, 0.1);
  box-shadow: 5px 5px 20px rgba(51, 52, 72, 0.05);
  border-radius: 12px;
  padding: 0;
  .dropdown-item {
    border-bottom: 1px solid #ebebf6;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    &:last-child {
      border: 0;
    }
  }
}
.payslip-title {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
}
.pay-head-roll {
  h5 {
    color: $color_3;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
.invoice-counts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    span {
      font-size: 15px;
      font-weight: 500;
      color: $color_91;
    }
  }
  a {
    padding: 3px 23px;
    font-size: 15px;
    border-radius: 8px;
    margin-left: 15px;
  }
}
.payroll-head-clinic {
  margin-bottom: 20px;
}
.payrolss-counts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 2px solid rgba(46, 55, 164, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
  h5 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
  }
}
.payslip-head-roll {
  h5 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
.half-day {
  width: 15px;
}
.table-inbox {
  input[type="radio"] {
    cursor: pointer;
  }
  input[type="checkbox"] {
    cursor: pointer;
  }
  .starred.fa-star {
    color: $color_74;
  }
  font-size: 14px;
  margin-bottom: 0;
  tr {
    cursor: pointer;
  }
}
.mail-list {
  list-style: none;
  padding: 0;
  > li {
    > a {
      color: $color_13;
      display: block;
      padding: 10px;
    }
  }
  > li.active {
    > a {
      color: $color_6;
      font-weight: bold;
    }
  }
}
.unread {
  .name {
    color: $color_4;
    font-weight: 600;
  }
  .subject {
    color: $color_4;
    font-weight: 600;
  }
  .mail-date {
    color: $color_4;
    font-weight: 600;
  }
}
.table.table-inbox {
  > tbody {
    > tr {
      > td {
        border-bottom: 1px solid #f2f2f2;
        border-top: 0;
      }
      > th {
        border-bottom: 1px solid #f2f2f2;
        border-top: 0;
      }
    }
  }
  > tfoot {
    > tr {
      > td {
        border-bottom: 1px solid #f2f2f2;
        border-top: 0;
      }
      > th {
        border-bottom: 1px solid #f2f2f2;
        border-top: 0;
      }
    }
  }
  > thead {
    > tr {
      > td {
        border-bottom: 1px solid #f2f2f2;
        border-top: 0;
      }
      > th {
        border-bottom: 1px solid #f2f2f2;
        border-top: 0;
      }
    }
  }
}
.note-editor.note-frame {
  border: 1px solid #ccc;
  box-shadow: inherit;
  .note-statusbar {
    background-color: $background-color_1;
  }
}
.note-editor.note-frame.fullscreen {
  top: 60px;
}
.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}
.form-control.search-message {
  border-radius: 4px;
  margin-left: 5px;
  width: 180px;
  padding: 0.375rem 0.75rem;
  min-height: 35px;
  margin-bottom: 5px;
}
table.table-inbox {
  tbody {
    tr.checked {
      background-color: $background-color_43;
    }
  }
}
.mail-label {
  margin-right: 5px;
}
.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border: 1px solid #eee;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 200px;
  }
}
.attach-file {
  color: $color_54;
  font-size: 70px;
  padding: 10px;
  text-align: center;
  min-height: 153px;
  > i {
    line-height: 133px;
  }
  img {
    height: auto;
    max-width: 100%;
  }
}
.attach-info {
  background-color: $background-color_44;
  padding: 10px;
}
.attach-filename {
  color: $color_54;
  font-weight: bold;
}
.attach-filesize {
  color: $color_75;
  font-size: 12px;
}
.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
  .btn-white {
    min-width: 102px;
  }
}
.sender-name {
  display: block;
}
.receiver-name {
  color: $color_54;
}
.right-action {
  text-align: right;
}
.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}
.mail-view-action {
  float: right;
}
.mail-sent-time {
  float: right;
}
.blog {
  position: relative;
  margin: 0 0 25px;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
}
.blog-image {
  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  > a {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
  }
  img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    -moz-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    border-radius: 16px;
  }
  a {
    &:hover {
      img {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}
.blog-content {
  position: relative;
  p {
    color: $color_76;
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 25px;
  }
}
.blog-title {
  color: $color_4;
  font-weight: normal;
  margin: 0 0 20px;
}
.read-more {
  color: $color_77;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
  &:hover {
    color: $color_6;
    margin-left: 10px;
    -moz-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
  }
  i {
    color: $color_3;
    font-size: 18px;
    margin-right: 3px;
  }
}
.blog-info {
  border: 1px solid #eaeaea;
  color: $color_78;
  font-size: 12px;
  margin-bottom: 0;
  padding: 12px 20px;
  a {
    color: $color_78;
    margin-right: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $color_14;
    }
  }
  i {
    color: $color_23;
    font-size: 18px;
    margin-right: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.post-left {
  float: right;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.post-right {
  float: left;
}
.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
.widget {
  margin-bottom: 30px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  h5 {
    color: $color_32;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
  }
  h3 {
    color: $color_1;
    font-size: 18px;
    margin: 0 0 20px;
  }
}
.search-form {
  .form-control {
    border: 1px solid #eaeaea;
    border-radius: 0;
    border-right: 0;
    box-shadow: inherit;
    height: 40px;
    color: $color_79;
  }
  .btn-primary {
    background-color: $background-color_17;
    border-color: $border-color_12;
    border-left: none;
    color: $color_6;
    height: 40px;
    border-radius: 0;
  }
}
.latest-posts {
  margin: 0;
  padding: 0;
  li {
    display: table;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    &:last-child {
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
}
.post-thumb {
  width: 80px;
  float: left;
  overflow: hidden;
  border-radius: 16px;
  a {
    img {
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -moz-transition: all 0.3s ease-in-out 0s;
      -ms-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      border-radius: 16px;
    }
    &:hover {
      img {
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
      }
    }
  }
}
.post-info {
  margin-left: 95px;
  h4 {
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin: 0 0 10px;
    a {
      color: $color_1;
      font-size: 14px;
      font-weight: 600;
    }
  }
  p {
    color: $color_3;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    i {
      color: $color_3;
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
.categories {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 12px 0;
    font-size: 16px;
    color: $color_1;
    font-weight: 600;
    &:last-child {
      padding-bottom: 0px;
      border-bottom: none;
    }
    a {
      color: $color_80;
      font-size: 14px;
      &:hover {
        color: $color_6;
        margin-left: 10px;
        -moz-transition: all 0.2s linear 0s;
        -ms-transition: all 0.2s linear 0s;
        -o-transition: all 0.2s linear 0s;
        -webkit-transition: all 0.2s linear 0s;
        transition: all 0.2s linear 0s;
      }
    }
  }
  > li {
    > a {
      > i {
        color: $color_6;
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
}
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  li {
    float: left;
  }
}
.tag {
  background: rgba(51, 52, 72, 0.1);
  border-radius: 8px;
  color: $color_1;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    background-color: $background-color_7;
    color: $color_16;
  }
}
.comment-by {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
}
.comments-list {
  .comment-block {
    p {
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      text-align: justify;
      color: $color_23;
      font-weight: 500;
    }
  }
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    clear: both;
    padding: 10px 0 0 50px;
    .comment {
      margin-bottom: 30px;
    }
    .comment-author {
      margin-left: -50px;
      position: absolute;
    }
    img.avatar {
      height: 40px;
      width: 40px;
      border-radius: 58px;
    }
  }
}
blockquote {
  p {
    font-style: italic;
    font-weight: 400;
  }
}
.grid-blog {
  .blog-title {
    font-size: 16px;
    margin: 0 0 10px;
    a {
      color: $color_1;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .blog-content {
    p {
      font-size: 14px;
      margin: 0 0 15px;
      color: $color_23;
    }
  }
  .blog-info {
    border: 0;
    border-top: 1px solid #eaeaea;
    margin-bottom: 0;
    padding: 12px 0;
  }
  .blog-image {
    margin-bottom: 20px;
    border-radius: 16px;
  }
  .read-more {
    font-size: 14px;
    margin-bottom: 0px;
    color: $color_3;
    font-weight: 500;
  }
}
.product-thumbnail {
  position: relative;
  margin-bottom: 0;
  margin-top: 10px;
}
.product-remove {
  position: absolute;
  top: -10px;
  right: -6px;
  color: $color_81;
  cursor: pointer;
}
.bootstrap-tagsinput {
  width: 100%;
  height: auto;
  box-shadow: inherit;
  border-radius: 8px;
  line-height: 30px;
  .badge {
    [data-role="remove"] {
      &:hover {
        box-shadow: inherit;
        color: $color_62;
      }
    }
  }
  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: $background-color_17;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
    &::placeholder {
      font-size: 14px;
    }
  }
}
.blog-views {
  position: absolute;
  top: 20px;
  left: 15px;
  background: #f5f6fa;
  border-radius: 6px;
  padding: 4px 10px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  font-size: 12px;
  h5 {
    color: $color_3;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
  }
  li {
    color: $color_3;
    font-weight: 600;
    &:first-child {
      margin-right: 10px;
    }
  }
}
.view-blog-list.blog-views {
  bottom: 20px;
  top: inherit;
}
.blog-grp-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 25px;
  span {
    color: $color_3;
    font-size: 12px;
    font-weight: 500;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
.blog-img-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.content-blk-blog {
  h4 {
    a {
      font-size: 15px;
      font-weight: 600;
      color: $color_1;
    }
    margin-bottom: 0;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: $color_30;
  }
}
.blog-view {
  .blog-title {
    font-size: 18px;
    font-weight: 500;
  }
  .blog-info {
    border: 0 none;
    margin-bottom: 20px;
    padding: 0;
  }
}
.social-share {
  float: left;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
  > li {
    display: inline-block;
    float: left;
    margin-left: 10px;
    text-align: center;
    &:first-child {
      margin-left: 0;
    }
    > a {
      background: rgba(51, 52, 72, 0.05);
      border-radius: 8px;
      color: $color_6;
      font-size: 22px;
      height: 40px;
      width: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      &:hover {
        background-color: $background-color_11;
        border-color: $border-color_1;
        img {
          filter: invert(1) brightness(100);
        }
      }
    }
  }
}
.share-blogs {
  a {
    background: rgba(51, 52, 72, 0.05);
    border-radius: 8px;
    color: $color_6;
    font-size: 22px;
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  span {
    color: $color_23;
    font-size: 12px;
    font-weight: 600;
  }
}
.blog-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.blog-reply {
  > a {
    color: $color_3;
    font-size: 12px;
    font-weight: 500;
  }
}
.blog-date {
  color: $color_75;
  font-size: 12px;
}
.blog-author-name {
  color: $color_6;
  font-size: 18px;
}
.text-red {
  color: $color_62;
}
.new-comment {
  label {
    font-size: 14px;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 20px;
  }
}
.comment-submit {
  .btn {
    background-color: $background-color_3;
    border-color: $border-color_4;
    border-radius: 0;
    font-size: 18px;
    padding: 8px 26px;
    color: $color_16;
  }
}
.about-author-img {
  background-color: $background-color_1;
  height: 120px;
  overflow: hidden;
  position: absolute;
  width: 120px;
}
.author-details {
  margin-left: 145px;
  .blog-author-name {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.about-author {
  min-height: 120px;
}
.blog-navigation {
  text-align: right;
}
.read-blks {
  a {
    color: $color_23;
    font-weight: 600;
  }
}
.date-blks {
  li {
    a {
      color: $color_23;
      font-weight: 600;
    }
  }
}
.authr-blog-group {
  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    color: $color_23;
    font-size: 12px;
    margin-bottom: 20px;
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: $color_30;
  }
}
.social-blk {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  li {
    padding: 0px 10px;
    a {
      &:hover {
        img {
          filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg)
            brightness(50%) contrast(340%);
        }
      }
    }
  }
}
.week-list {
  color: $color_30;
  font-size: 10px;
  font-weight: 500;
}
.week-group {
  h5 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
  }
}
.list-add-blogs {
  margin-top: 25px;
  ul {
    li {
      background: rgba(51, 52, 72, 0.1);
      border-radius: 8px;
      padding: 5px 10px;
      margin-right: 10px;
      color: $color_1;
      font-size: 12px;
      font-weight: 500;
      &:hover {
        background: rgba(51, 52, 72, 0.3);
      }
      &:last-child {
        margin-right: 00px;
      }
    }
  }
}
.relat-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 2px solid rgba(46, 55, 164, 0.05);
  padding-bottom: 20px;
  margin-bottom: 20px;
  h5 {
    margin-bottom: 0;
  }
  a {
    font-weight: 500;
    font-size: 14px;
    color: $color_3;
  }
}
.date-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  h5 {
    background: rgba(51, 52, 72, 0.1);
    border-radius: 8px;
    font-weight: 600;
    font-size: 10px;
    padding: 5px 10px;
    margin-bottom: 0;
  }
  span {
    color: $color_23;
    font-weight: 500;
    font-size: 12px;
  }
}
.pagination-box {
  .pagination {
    margin-top: 0;
  }
}
.tab-content {
  padding-top: 20px;
}
.error-pages {
  background: #fff;
}
.error-box {
  border-radius: 5px;
  line-height: 1;
  margin: 0 auto;
  max-width: 620px;
  padding: 50px 30px 55px;
  text-align: center;
  width: 100%;
  h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 40px;
    color: $color_23;
  }
  img {
    margin-bottom: 50px;
  }
  .btn {
    text-transform: capitalize;
    min-width: 179px;
    padding: 10px 15px;
    border-radius: 12px;
  }
}
.error-wrapper {
  margin: 0;
  color: $color_82;
  -moz-box-align: center;
  -moz-box-pack: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  background: #fff;
}
.lock-user {
  margin-bottom: 20px;
  img {
    margin-bottom: 15px;
    width: 100px;
  }
}
.voice-call-avatar {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  .call-avatar {
    margin: 15px;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 3px;
    background-color: $background-color_1;
  }
  .call-timing-count {
    padding: 5px;
  }
  .username {
    font-size: 18px;
    text-transform: uppercase;
  }
}
.call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}
.user-video {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
  video {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
}
.my-video {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      float: left;
      width: 120px;
      margin-right: 10px;
      img {
        border: 3px solid #fff;
        border-radius: 6px;
      }
    }
  }
}
.end-call {
  position: absolute;
  top: 7px;
  right: 0;
  a {
    color: $color_16;
    border-radius: 50px;
    display: inline-block;
    background-color: $background-color_45;
    padding: 8px 25px;
    text-transform: uppercase;
  }
}
.call-users {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      float: left;
      width: 80px;
      margin-left: 10px;
      img {
        border-radius: 6px;
        padding: 2px;
        background-color: $background-color_1;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      a {
        &:hover {
          .call-mute {
            display: block;
          }
        }
      }
    }
  }
}
.call-mute {
  width: 80px;
  height: 80px;
  background-color: $background-color_46;
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  border-radius: 6px;
  font-size: 30px;
  color: $color_16;
  display: none;
  top: 0;
  border: 3px solid transparent;
}
.call-details {
  margin: 10px 0 0px;
  display: flex;
}
.call-info {
  margin-left: 10px;
  width: 100%;
}
.call-user-details {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.call-timing {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $color_83;
  display: flex;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}
.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}
.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}
.video-window {
  .fixed-header {
    padding: 0;
    border: 0;
    .nav {
      > li {
        > a {
          padding: 19px 15px;
        }
      }
    }
  }
}
.incoming-btns {
  margin-top: 20px;
}
.modal.custom-modal {
  background-color: $background-color_47;
}
.custom-modal {
  .modal-content {
    border: 0;
    background-color: $background-color_1;
  }
  .profile-widget {
    margin-bottom: 0;
  }
  .modal-body {
    padding: 30px;
  }
}
.modal.custom-modal.modal-bg {
  background-color: $background-color_47;
}
.custom-modal.modal-bg {
  .modal-content {
    background-color: $background-color_1;
    text-align: center;
    border-radius: 10px;
  }
}
.form-header {
  text-align: center;
  margin-bottom: 30px;
}
.invoice-load-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  .btn {
    font-weight: 700;
    font-size: 14px;
    color: $color_6;
    min-width: 170px;
    padding: 10px 15px;
    background: #ffffff;
    border: 2px solid #009efb;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: $color_14;
    min-width: 170px;
    padding: 10px 15px;
    background: #ffffff;
    border: 2px solid #00d3c7;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover {
      span {
        border: 3px solid #ffffff7a;
        border-right-color: $border-right-color_1;
      }
      background: #009efb;
      color: $color_16;
    }
    span {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 8px;
      vertical-align: 0;
      border: 3px solid #9a55ff21;
      border-right-color: $border-right-color_2;
      border-radius: 50%;
      -webkit-animation: 0.75s linear infinite spinner-border;
      animation: 0.75s linear infinite spinner-border;
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 8px;
      vertical-align: 0;
      border: 3px solid rgb(0 211 199 / 57%);
      border-right-color: $border-right-color_3;
      border-radius: 50%;
      -webkit-animation: 0.75s linear infinite spinner-border;
      animation: 0.75s linear infinite spinner-border;
    }
  }
}
.invoices-page-header {
  background: #ffffff;
  border-radius: 10px;
  padding: 12px;
  .invoices-breadcrumb-item {
    i {
      color: $color_16;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-size: 10px;
      margin-right: 10px;
      background: #2e37a4;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
    a {
      font-weight: 500;
      color: $color_17;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
  }
}
.page-header {
  .breadcrumb {
    background-color: $background-color_17;
    color: $color_84;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
  }
  margin-bottom: 1.875rem;
}
.invoices-create-btn {
  .invoices-preview-link {
    font-weight: 500;
    color: $color_17;
    margin-right: 30px;
    font-size: 15px;
  }
  .delete-invoice-btn {
    background: #6c757d;
    margin-right: 10px;
  }
  .btn {
    font-weight: 500;
    font-size: 15px;
    color: $color_16;
    border-radius: 8px;
    padding: 10px 0px;
    min-width: 160px;
    position: relative;
  }
  .save-invoice-btn {
    background: #2e37a4;
  }
}
.check {
  &:checked {
    + {
      .checktoggle {
        background-color: $background-color_3;
        border: 1px solid #009efb;
        background-color: $background-color_11;
        border: 1px solid #2e37a4;
        &:after {
          background-color: $background-color_1;
          left: 100%;
          transform: translate(calc(-100% - 5px), -50%);
        }
      }
      .checktoggles {
        background-color: $background-color_3;
        border: 1px solid #009efb;
        &:after {
          background-color: $background-color_1;
          left: 100%;
          transform: translate(calc(-100% - 5px), -50%);
        }
      }
    }
  }
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.invoices-add-card {
  background: #ffffff;
  border-radius: 10px;
}
.invoices-main-form {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  label {
    font-size: 14px;
  }
}
.invoice-details-title {
  font-weight: 500;
  font-size: 18px;
  color: $color_63;
  margin-bottom: 0.5rem;
}
.invoice-details-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.invoice-inner-footer {
  padding: 0;
  .form-control {
    position: absolute;
    color: $color_6;
    background: transparent;
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0 10px;
    position: relative;
    color: $color_6;
    background: transparent;
    border: 0;
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 0 10px;
  }
  span {
    font-weight: 500;
    color: $color_63;
    position: relative;
    font-weight: 500;
    color: $color_63;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}
.invoice-inner-date {
  padding: 26px;
  border-right: 1px solid #e5e5e5;
  padding: 8px 15px;
  border-right: 1px solid #e5e5e5;
}
.invoice-item {
  .customer-text {
    font-size: 18px;
    color: $color_4;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
    font-size: 20px;
    color: $color_4;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
    a {
      font-size: 14px;
      color: $color_6;
      margin-left: 12px;
      font-size: 14px;
      color: $color_17;
      margin-left: 12px;
    }
  }
  .invoice-details-two {
    text-align: left;
    color: $color_4;
    float: left;
    text-align: left !important;
    color: $color_4;
    font-size: 13px;
  }
  .invoice-logo {
    margin-bottom: 30px;
    img {
      width: auto;
      max-height: 52px;
    }
  }
  .invoice-text {
    h2 {
      color: $color_89;
      font-size: 36px;
      font-weight: 600;
      font-weight: 400;
    }
    padding-top: 42px;
    padding-bottom: 36px;
  }
  .invoice-details {
    text-align: right;
    font-weight: 400;
    strong {
      color: $color_89;
    }
  }
}
.invoice-add-table {
  h4 {
    font-weight: 600;
    font-size: 18px;
    color: $color_17;
    margin-bottom: 30px;
  }
  .add-btns {
    color: $color_17;
  }
  .copy-btn {
    color: $color_85;
  }
  .remove-btn {
    color: $color_22;
  }
  th {
    background: #f8f9fa;
    padding: 15px;
    font-size: 14px;
  }
  padding-top: 20px;
  padding-bottom: 30px;
}
.invoice-fields {
  .field-title {
    font-weight: 600;
    font-size: 16px;
    color: $color_63;
    margin-bottom: 15px;
  }
}
.invoice-faq {
  .faq-tab {
    padding-top: 10px;
  }
}
.faq-tab {
  .panel-title {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 0px;
    position: relative;
    font-size: 14px;
    a {
      color: $color_85;
      &:not(.collapsed) {
        &::after {
          color: $color_22;
          color: $color_22;
        }
      }
      &:hover {
        color: $color_63;
      }
      &::after {
        content: "\f068";
        font-family: $font-family_7;
        position: absolute;
        right: 20px;
        top: 10px;
        color: $color_86;
        font-weight: 600;
        font-size: 14px;
      }
    }
    a.collapsed {
      &::after {
        content: "\f067";
        font-family: $font-family_7;
        position: absolute;
        right: 20px;
        top: 10px;
        color: $color_86;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .panel-body {
    textarea {
      min-height: 100px;
      border: 0;
      border-radius: 0 0 4px 4px;
    }
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }
}
.invoice-total-card {
  .invoice-total-title {
    font-weight: 600;
    font-size: 16px;
    color: $color_63;
    margin-bottom: 15px;
  }
}
.invoice-total-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  p {
    color: $color_63;
    margin-bottom: 20px;
    position: relative;
    text-align: initial;
    font-size: 14px;
    span {
      float: right;
    }
  }
  .add-links {
    font-weight: 500;
    color: $color_6;
    margin-bottom: 20px;
    display: block;
  }
  .service-trash-one {
    margin-top: 10px;
    color: $color_63;
  }
  .service-amount {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }
  .service-trash {
    margin-bottom: 10px;
    color: $color_63;
    i {
      color: $color_88;
    }
  }
  .service-trash1 {
    margin-bottom: 10px;
    color: $color_63;
  }
  .add-links1 {
    font-weight: 500;
    color: $color_17;
    margin-bottom: 20px;
    display: block;
    font-size: 14px;
  }
}
.invoice-total-footer {
  border-top: 1px solid #e5e5e5;
  padding: 15px;
  h4 {
    font-size: 16px;
    margin-bottom: 0;
    color: $color_17;
    text-align: initial;
    span {
      float: right;
    }
  }
}
.service-upload {
  border: 2px dashed #e5e5e5;
  text-align: center;
  padding: 30px 0;
  background-color: $background-color_1;
  position: relative;
  width: 330px;
  span {
    font-size: 15px;
    color: $color_87;
    display: block;
  }
  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
#show-invoices {
  display: none;
}
.checktoggles {
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 8px;
    height: 8px;
    background-color: $background-color_1;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
  }
  background-color: $background-color_48;
  border: 1px solid #c4c4c4;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0;
  height: 15px;
  margin-left: 10px;
  top: 3px;
  margin-bottom: 0;
  position: absolute;
  width: 30px;
  display: inline-block;
}
.add-links-one {
  font-weight: 500;
  color: $color_17;
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
}
td.add-remove {
  padding: 20px;
}
.add-remove {
  i {
    font-size: 18px;
    cursor: pointer;
  }
}
.bank-details {
  .modal-content {
    border: 1px solid #f2ecff;
    border-radius: 6px;
  }
  .modal-header {
    border: 0;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #e5e5e5;
  }
  .close {
    background: transparent;
    border: 0;
    color: $color_63;
    font-size: 28px;
    line-height: normal;
    top: 20px;
    width: auto;
    height: auto;
    right: 20px;
  }
  .modal-body {
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
  }
  .bank-inner-details {
    .form-control {
      height: 50px;
    }
  }
  .modal-footer {
    justify-content: flex-end;
    padding: 30px;
    > * {
      margin: 0;
    }
  }
}
.bank-details-btn {
  .bank-cancel-btn {
    background: #1b2559;
  }
  .bank-save-btn {
    background: #2e37a4;
  }
  .btn {
    min-width: 160px;
    border-radius: 8px;
    padding: 10px 0;
    color: $color_16;
  }
}
.invoice-info-card {
  border-radius: 14px;
  border-radius: 14px;
}
.invoice-item-one {
  border-bottom: 1px solid rgba(231, 234, 252, 0.75);
  border-bottom: 1px solid rgba(231, 234, 252, 0.75);
  .invoice-info {
    margin-bottom: 30px;
    text-align: right;
  }
  .invoice-details {
    color: $color_63;
    font-weight: 400;
  }
}
.inovices-month-info {
  padding-top: 50px;
}
.invoice-info.invoice-info2 {
  text-align: right;
}
.invoice-table {
  tr {
    th {
      color: $color_89;
      font-weight: 600;
      padding: 15px 20px;
      line-height: inherit;
    }
    td {
      color: $color_89;
      font-weight: 600;
      padding: 15px 20px;
      line-height: inherit;
      font-weight: 500;
    }
  }
  > thead {
    > tr {
      > th {
        background: #f5f7ff;
        border-top: 0;
        font-weight: 500;
        font-size: 14px;
        color: $color_63;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        font-weight: 400;
        color: $color_63;
        padding: 15px 20px;
        font-size: 14px;
      }
    }
  }
}
.invoice-table-two {
  tr {
    th {
      color: $color_89;
      font-weight: 600;
      padding: 15px 20px;
      line-height: inherit;
      border-top: 0;
    }
    td {
      color: $color_89;
      font-weight: 600;
      padding: 15px 20px;
      line-height: inherit;
      font-weight: 500;
      border-top: 0;
      text-align: right;
    }
  }
  margin-bottom: 0;
}
.other-info {
  margin-top: 10px;
}
.file {
  visibility: hidden;
  position: absolute;
}
.btn-group {
  position: relative;
  display: -inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical {
  position: relative;
  display: -inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.font-size-14 {
  font-size: 14px;
}
.customer-text-one {
  font-size: 16px;
  color: $color_63;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.invoice-name {
  color: $color_63;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.invoice-head {
  h2 {
    font-weight: 600;
    font-size: 22px;
    color: $color_63;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  margin-bottom: 30px;
  p {
    font-weight: 400;
    font-size: 16px;
    color: $color_63;
    margin-bottom: 0;
  }
}
.invoice-item-two {
  padding-top: 30px;
  padding-bottom: 30px;
  .invoice-details {
    color: $color_63;
    font-weight: 400;
    width: 100%;
  }
}
.invoice-item-box {
  background: #55ddff30;
  border-radius: 5px;
  padding: 15px;
  width: 200px;
  float: right;
  margin-top: 20px;
  p {
    color: $color_63;
    font-weight: 400;
    margin-bottom: 10px;
  }
}
.invoice-issues-box {
  background: #2e37a4;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  margin-bottom: 30px;
}
.invoice-issues-date {
  padding-top: 20px;
  padding-bottom: 20px;
  p {
    font-weight: 600;
    font-size: 14px;
    color: $color_35;
    margin-bottom: 0;
  }
}
.invoice-table-wrap {
  margin-bottom: 30px;
}
.invoice-terms {
  margin-bottom: 30px;
  h6 {
    font-weight: 500;
    font-size: 15px;
    color: $color_63;
    margin-bottom: 6px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: $color_90;
  }
}
.invoice-sign {
  padding-top: 30px;
  span {
    color: $color_63;
    margin-top: 18px;
  }
}
.invoice-item-bg {
  background: #2e37a4;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  .invoice-info {
    border-right: 1px solid #fff;
    padding: 30px;
    margin-top: 20px;
    text-align: initial;
    display: flow-root;
  }
  .customer-text-one {
    color: $color_16;
  }
  .invoice-name {
    color: $color_16;
  }
  .invoice-details-two {
    color: $color_16;
  }
  .invoice-info-one {
    p {
      color: $color_16;
      margin-bottom: 10px;
    }
  }
}
.invoice-circle-img {
  position: relative;
  .invoice-circle1 {
    position: absolute;
    left: 0;
    opacity: 0.1;
  }
  .invoice-circle2 {
    position: absolute;
    right: 0;
    opacity: 0.1;
  }
}
.invoice-payment-box {
  text-align: left;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: $color_63;
    margin-bottom: 16px;
  }
  .payment-details {
    background: transparent;
    border: 2px dashed #009efb;
    p {
      font-weight: 600;
      font-size: 15px;
      color: $color_63;
    }
  }
}
.invoice-sign-box {
  margin-top: 30px;
  text-align: initial;
}
.invoices-preview {
  .modal-body {
    padding: 0;
  }
}
.paid-continue-btn {
  background-color: $background-color_11;
  border: 1px solid #2e37a4;
  border-radius: 5px;
  color: $color_16;
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  text-align: center;
}
.paid-cancel-btn {
  background-color: $background-color_1;
  border: 1px solid #2e37a4;
  border-radius: 5px;
  color: $color_17;
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  text-align: center;
}
.invoices-table.custom-table {
  tbody {
    tr {
      box-shadow: none;
      td {
        vertical-align: middle;
      }
    }
  }
  thead {
    tr {
      th {
        color: $color_1;
      }
    }
  }
}
.add-table-invoice {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  i {
    color: $color_3;
  }
}
.grand-totals {
  tr {
    td {
      &:first-child {
        width: 75%;
      }
    }
  }
}
.invoice-clinic {
  li {
    color: $color_30;
    font-size: 14px;
    font-weight: 500;
  }
}
.bold-total-invoice {
  h5 {
    font-size: 22px;
    color: $color_1;
    font-weight: 600;
  }
}
.total-fonts {
  tr {
    td {
      color: $color_30;
      font-weight: 500;
    }
  }
}
.invoice-head-clinic {
  border-bottom: 2px solid rgba(46, 55, 164, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.chat-sidebar {
  background-color: $background-color_1;
  border-left: 1px solid #eaeaea;
  width: 300px;
  .chat-contents {
    background-color: $background-color_1;
  }
  .chat-left {
    .chat-content {
      border: 0;
      padding: 0;
      border-radius: 0 !important;
      background-color: $background-color_17;
      max-width: 100%;
    }
    .chat-body {
      margin-left: 50px;
      padding-right: 0;
    }
    .chat-time {
      color: $color_20;
      display: inline-block;
      font-size: 11px;
    }
  }
  .chat-date {
    background-color: $background-color_1;
  }
  .chats {
    padding: 15px 15px 30px;
  }
  .avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .chat.chat-left {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .chat-content {
    > p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}
.chat-sidebar.opened {
  margin-right: 0;
}
.lg-backdrop {
  z-index: 1041;
}
#lightgallery {
  .img-thumbnail {
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  }
}
.settings-menu-links {
  .nav-tabs.menu-tabs {
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.settings-form {
  margin-top: 20px;
  .custom_radio {
    color: $color_4;
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-size: 0.9375rem;
    padding-left: 30px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $background-color_1;
      border: 1px solid #2e37a4;
      border-radius: 50%;
      &:after {
        display: block;
        content: "";
        position: absolute;
        opacity: 0;
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #2e37a4;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .social-icon {
    width: 50px;
    height: 42px;
    margin-right: 6px;
    color: $color_16;
    background: #2e37a4;
    border: 1px solid #2e37a4;
    border-radius: 5px;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    padding: 4px;
  }
  .links-cont {
    .social-icon {
      &::after {
        content: "";
        border-top: 0.2em solid;
        border-right: 0.2em solid transparent;
        border-bottom: 0;
        border-left: 0.2em solid transparent;
        margin-left: 4px;
      }
    }
  }
  .trash {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 42px;
    width: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background-color: $background-color_11;
    color: $color_16;
    position: relative;
    font-size: 16px;
    margin-left: 6px;
  }
  .bootstrap-tagsinput {
    .tag {
      margin-right: 5px;
      margin-bottom: 0px;
      color: $color_40;
      background: #e4e4e4 !important;
      border-radius: 0px;
      font-size: 14px;
      font-weight: 500;
      padding: 0px 24px;
    }
    display: block;
    color: $color_13;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: unset;
    min-height: 46px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 4px;
  }
  .form-group {
    label {
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
}
.settings-label {
  margin-bottom: 10px;
  font-size: 13px;
}
.settings-size {
  font-size: 13px;
  color: $color_92;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
  span {
    font-weight: 700;
  }
}
.upload-images {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  margin-top: 30px;
  width: 196px;
  height: 78px;
  justify-content: space-around;
  align-items: center;
  position: relative;
  img {
    max-height: 45px;
    width: auto;
  }
  .btn-icon {
    color: $color_14;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    width: 20px;
    height: 20px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    right: 0px;
    top: 0px;
    justify-content: center;
    -webkit-justify-content: center;
  }
}
.upload-size {
  width: 82px;
  height: 82px;
}
.checktoggle {
  background-color: $background-color_1;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0 !important;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: $background-color_49;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
  }
}
form {
  .btn-gradient-primary {
    font-size: 14px;
    padding: 8px 40px;
    color: $color_16;
  }
  .btn-secondary {
    font-size: 14px;
    padding: 7px 30px;
    color: $color_16;
  }
}
.card-header {
  padding: 15px;
}
.custom_radio {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          &::after {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.add-links {
  background-color: $background-color_11;
  color: $color_16 !important;
  font-size: 13px;
  font-weight: 700;
  padding: 10px 26px;
  border-radius: 4px;
}
.card.invoices-grid-card {
  .card-footer {
    background-color: $background-color_1;
  }
}
.card-block {
  tr {
    th {
      font-size: 14px;
    }
    td {
      font-size: 14px;
    }
  }
}
.modal-body {
  label {
    font-size: 13px;
  }
}
@media only screen and (min-width: 991px) {
  #toggle_btn {
    color: $color_16;
    float: left;
    font-size: 20px;
    line-height: 70px;
    padding: 0 10px;
  }
  .user-active {
    .call-imgs {
      width: 100%;
    }
  }
  .join-video.user-active {
    height: auto;
  }
  .single-user {
    .call-imgs {
      width: 100%;
    }
  }
  .mini-sidebar {
    .header-left {
      .logo {
        span {
          display: none;
        }
      }
    }
    .header {
      .header-left {
        padding: 0 5px;
        width: 60px;
      }
    }
    .sidebar {
      width: 60px;
      .sidebar-menu {
        ul {
          > li {
            > a {
              span {
                display: none;
                transition: all 0.2s ease-in-out;
                opacity: 0;
              }
              span.menu-side {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .menu-title {
      visibility: hidden;
      white-space: nowrap;
    }
    .page-wrapper {
      margin-left: 60px;
    }
    .sidebar-menu {
      > ul {
        > li {
          > a {
            padding: 12px 10px;
          }
        }
      }
    }
  }
  .mini-sidebar.expand-menu {
    .sidebar {
      width: 230px;
      .sidebar-menu {
        ul {
          > li {
            > a {
              span {
                display: flex;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .menu-title {
      visibility: visible;
    }
    .sidebar-menu {
      > ul {
        > li {
          > a {
            padding: 12px 20px;
          }
        }
      }
    }
  }
  .modal-open {
    .mini-sidebar {
      .sidebar {
        z-index: 1051;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .modal-md {
    width: 500px;
  }
  .nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
    > li {
      > a {
        border-width: 2px 0 0 0;
        &:hover {
          border-width: 2px 0 0 0;
        }
        &:focus {
          border-width: 2px 0 0 0;
        }
      }
    }
  }
  .nav-tabs.nav-tabs-top {
    > li {
      margin-bottom: 0;
      > a {
        border-width: 2px 0 0 0;
        &:hover {
          border-width: 2px 0 0 0;
        }
        &:focus {
          border-width: 2px 0 0 0;
        }
      }
      + {
        li {
          > a {
            margin-left: 1px;
          }
        }
      }
      > a.active {
        border-top-color: $border-top-color_1;
        &:hover {
          border-top-color: $border-top-color_1;
        }
        &:focus {
          border-top-color: $border-top-color_1;
        }
      }
    }
  }
  .nav-tabs.nav-tabs-bottom {
    > li {
      margin-bottom: -1px;
      margin-bottom: -1px;
      > a.active {
        border-bottom-width: 2px;
        border-color: $border-color_3;
        border-bottom-color: $border-bottom-color_2;
        background-color: $background-color_17;
        transition: none 0s ease 0s;
        -moz-transition: none 0s ease 0s;
        -o-transition: none 0s ease 0s;
        -ms-transition: none 0s ease 0s;
        -webkit-transition: none 0s ease 0s;
        border-bottom-width: 2px;
        border-color: $border-color_3;
        border-bottom-color: $border-bottom-color_3;
        background-color: $background-color_17;
        transition: none 0s ease 0s;
        -moz-transition: none 0s ease 0s;
        -o-transition: none 0s ease 0s;
        -ms-transition: none 0s ease 0s;
        -webkit-transition: none 0s ease 0s;
        &:hover {
          border-bottom-width: 2px;
          border-color: $border-color_3;
          border-bottom-color: $border-bottom-color_2;
          background-color: $background-color_17;
          transition: none 0s ease 0s;
          -moz-transition: none 0s ease 0s;
          -o-transition: none 0s ease 0s;
          -ms-transition: none 0s ease 0s;
          -webkit-transition: none 0s ease 0s;
          border-bottom-width: 2px;
          border-color: $border-color_3;
          border-bottom-color: $border-bottom-color_3;
          background-color: $background-color_17;
          transition: none 0s ease 0s;
          -moz-transition: none 0s ease 0s;
          -o-transition: none 0s ease 0s;
          -ms-transition: none 0s ease 0s;
          -webkit-transition: none 0s ease 0s;
        }
        &:focus {
          border-bottom-width: 2px;
          border-color: $border-color_3;
          border-bottom-color: $border-bottom-color_2;
          background-color: $background-color_17;
          transition: none 0s ease 0s;
          -moz-transition: none 0s ease 0s;
          -o-transition: none 0s ease 0s;
          -ms-transition: none 0s ease 0s;
          -webkit-transition: none 0s ease 0s;
          border-bottom-width: 2px;
          border-color: $border-color_3;
          border-bottom-color: $border-bottom-color_3;
          background-color: $background-color_17;
          transition: none 0s ease 0s;
          -moz-transition: none 0s ease 0s;
          -o-transition: none 0s ease 0s;
          -ms-transition: none 0s ease 0s;
          -webkit-transition: none 0s ease 0s;
        }
      }
    }
  }
  .nav-tabs.nav-tabs-solid {
    background-color: $background-color_31;
    border: 0;
    > li {
      margin-bottom: 0;
      > a {
        border-color: $border-color_3;
        &:hover {
          background-color: $background-color_33;
        }
        &:focus {
          background-color: $background-color_33;
        }
      }
    }
    > .open {
      &:not(.active) {
        > a {
          background-color: $background-color_33;
          border-color: $border-color_3;
        }
      }
    }
  }
  .nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
    > li {
      > a {
        border-width: 2px 0 0 0;
        &:hover {
          border-width: 2px 0 0 0;
        }
        &:focus {
          border-width: 2px 0 0 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1199.98px) {
  .invoices-view {
    width: 90%;
    float: right;
  }
  .invoices-tabs {
    ul {
      li {
        margin-right: 10px;
        a {
          font-size: 14px;
        }
      }
    }
  }
  .login-wrap-bg {
    padding: 15px 50px 15px;
  }
  .login-wrapper {
    .loginbox {
      .login-right {
        padding: 30px;
      }
    }
  }
  .morning-user {
    padding: 30px 0px 30px 30px;
    h2 {
      font-size: 28px;
      font-weight: 600;
    }
  }
  .border-right-bg {
    &:before {
      display: none;
    }
    margin-bottom: 10px;
  }
  .wallet-widget {
    .circle-bar {
      > div {
        canvas {
          width: 140px !important;
          height: 140px !important;
        }
      }
    }
  }
  .meeting {
    .join-video {
      height: 320px;
    }
  }
  .join-video.single-user {
    height: auto;
  }
  .chart-user-icon img {
    display: none;
  }
}
@media only screen and (max-width: 991.98px) {
  .profile-rightbar {
    display: inline-block !important;
  }
  .chat-profile-view {
    display: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px;
    position: fixed;
    margin-right: -300px;
    display: table-cell;
    top: 0;
    padding-bottom: 60px;
    margin-top: 50px;
  }
  .message-view.chat-view {
    width: 100%;
  }
  .slide-nav {
    .page-wrapper {
      margin-left: 0;
    }
    .sidebar {
      margin-left: 0;
    }
  }
  .chat-main-row {
    left: 0;
  }
  .header {
    .header-left {
      position: absolute;
      width: 100%;
    }
  }
  .mobile_btn {
    display: block;
  }
  .page-title-box {
    display: none;
  }
  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  a.mobile_btn {
    color: $color_16;
    font-size: 24px;
    height: 70px;
    left: 0;
    line-height: 70px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 10;
  }
  .invoices-view {
    width: 100%;
    float: none;
  }
  #toggle_btn {
    display: none;
  }
  .app-listing {
    li {
      width: 49%;
      display: inline-block;
    }
    display: inline-block;
  }
  .multipleSelection {
    #checkBoxes {
      width: 100%;
    }
  }
  .SortBy {
    #checkBox {
      width: 100%;
    }
  }
  .inovices-month-info {
    padding-top: 20px;
  }
  .invoice-inner-date {
    padding: 10px;
  }
  .invoice-inner-date.invoice-inner-datepic {
    padding: 10px 0;
    padding: 10px;
  }
  .field-box {
    .btn {
      min-width: auto;
      border-radius: 4px;
      padding: 10px;
    }
  }
  .invoice-inner-head {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
  }
  .invoice-circle-img {
    display: none;
  }
  .invoice-item-bg {
    .invoice-info {
      border-right: 1px solid #fff;
      padding: 10px 30px;
      margin-top: 20px;
      border-right: 1px solid #fff;
      padding: 10px 30px;
      margin-top: 0;
    }
  }
  .invoice-info {
    margin-bottom: 0;
  }
  .payment-details {
    width: auto;
  }
  .invoices-preview {
    .modal-dialog {
      max-width: 700px;
    }
  }
  .invoice-item-one {
    .invoice-info {
      text-align: left;
    }
  }
  .invoice-head {
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
  .invoice-payment-box {
    .payment-details {
      margin-bottom: 20px;
    }
  }
  .invoice-faq {
    .faq-tab {
      padding-bottom: 10px;
    }
  }
  .service-upload {
    padding: 30px 0;
    width: auto;
  }
  .bank-details-btn {
    .btn {
      min-width: auto;
      padding: 10px;
    }
  }
  .invoice-info-card {
    .invoice-item-one {
      .invoice-info {
        text-align: right;
      }
    }
  }
  .login-wrap {
    display: none;
  }
  .account-logo {
    margin-bottom: 25px;
  }
  .login-wrapper {
    .loginbox {
      .login-right {
        h2 {
          margin-bottom: 25px;
          font-size: 20px;
        }
        .forgotpass {
          margin-bottom: 25px;
        }
      }
    }
  }
  .login-btn {
    margin-bottom: 25px;
  }
  .error-box {
    h3 {
      font-size: 26px;
    }
  }
  .mob-view {
    display: none;
  }
  .logo {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .morning-user {
    padding: 25px 0px 25px 25px;
    h2 {
      font-size: 26px;
    }
  }
  .user-menu.nav {
    li {
      margin-right: 6px;
    }
  }
  .profile-user-img {
    img {
      width: 60px;
    }
  }
  .profile-edit-icon {
    img {
      width: 15px;
    }
  }
  .names-profiles {
    h4 {
      font-size: 14px;
    }
    h5 {
      font-size: 12px;
    }
  }
  .doctor-follows {
    h5 {
      font-size: 12px;
    }
    h4 {
      font-size: 16px;
    }
  }
  .follow-btn-group {
    .btn-info {
      min-width: 90px;
      font-size: 12px;
      padding: 4px 15px;
    }
  }
  .meeting {
    .join-video {
      height: 320px;
    }
  }
  .join-video.single-user {
    height: auto;
  }
  .selection {
    width: 12%;
  }
  .chart-user-icon img {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  .table {
    .invoice-link {
      font-size: 14px;
    }
  }
  .table_footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .navbar-nav {
    .open {
      .dropdown-menu {
        float: left;
        position: absolute;
      }
    }
  }
  .notifications {
    right: -48px;
    &:before {
      right: 60px;
    }
    &:after {
      right: 60px;
    }
  }
  .modal-body.card-box {
    background-color: $background-color_1;
    border: none;
    border-radius: inherit;
    box-shadow: unset;
    margin-bottom: 0;
    padding: 15px;
  }
  .roles-menu {
    margin-bottom: 20px;
  }
  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }
  .right-action {
    text-align: center;
  }
  .top-action-left {
    .float-left {
      float: none !important;
    }
    .btn-group {
      margin-bottom: 15px;
    }
  }
  .top-action-right {
    text-align: center;
    a.btn.btn-white {
      margin-bottom: 15px;
    }
  }
  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }
  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
    > li {
      > a.active {
        border-color: $border-color_13;
        border-left-width: 2px;
        &:hover {
          border-color: $border-color_13;
          border-left-width: 2px;
        }
        &:focus {
          border-color: $border-color_13;
          border-left-width: 2px;
        }
      }
    }
  }
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: $background-color_1;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    .nav-item {
      margin-bottom: 0;
    }
    > li {
      > a {
        border-width: 2px;
        border-left-color: $border-left-color_1;
        &:hover {
          background-color: $background-color_31;
        }
        &:focus {
          background-color: $background-color_31;
        }
      }
      > a.active {
        background-color: $background-color_33;
        border-color: $border-color_13;
        border-left-width: 2px;
        &:hover {
          background-color: $background-color_33;
          border-color: $border-color_13;
          border-left-width: 2px;
        }
        &:focus {
          background-color: $background-color_33;
          border-color: $border-color_13;
          border-left-width: 2px;
        }
      }
    }
    .nav-link {
      border-width: 2px;
    }
    .nav-item.show {
      .nav-link {
        background-color: $background-color_33;
        border-color: $border-color_13;
        border-left-width: 2px;
      }
    }
    .nav-link.active {
      background-color: $background-color_33;
      border-color: $border-color_13;
      border-left-width: 2px;
    }
    > li.open {
      &:not(.active) {
        > a {
          background-color: $background-color_31;
          &:hover {
            background-color: $background-color_31;
          }
          &:focus {
            background-color: $background-color_31;
          }
        }
      }
    }
  }
  .nav-tabs.nav-tabs-solid {
    padding: 5px;
    > li {
      > a {
        border-left-width: 0 !important;
      }
    }
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }
  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
    > li {
      > a.active {
        border-width: 0 0 0 2px;
        border-left-color: $border-left-color_2;
        &:hover {
          border-width: 0 0 0 2px;
          border-left-color: $border-left-color_2;
        }
        &:focus {
          border-width: 0 0 0 2px;
          border-left-color: $border-left-color_2;
        }
      }
    }
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1.12em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.75em;
  }
  .page-title {
    font-size: 1.2em;
    padding-top: 10px;
  }
  .card-title {
    font-size: 1.12em;
  }
  .blog-view {
    .blog-title {
      font-size: 18px;
    }
  }
  .widget {
    h3 {
      font-size: 18px;
    }
  }
  .my-video {
    ul {
      li {
        width: 80px;
      }
    }
  }
  .voice-call-avatar {
    .call-avatar {
      width: 120px;
      height: 120px;
    }
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .header {
    .has-arrow {
      .dropdown-toggle {
        > span {
          &:nth-child(2) {
            display: none;
          }
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .btn-white {
    margin: 0px;
  }
  .login-wrapper {
    .loginbox {
      .login-right {
        .forgotpass {
          display: block;
        }
        padding: 20px;
      }
    }
  }
  .next-sign {
    display: block;
  }
  .remember-me {
    margin-bottom: 10px;
  }
  .login-right {
    .btn-primary {
      font-size: 14px;
      padding: 7px 15px;
    }
  }
  .account-subtitle {
    margin-bottom: 15px;
  }
  .login-wrap-bg {
    padding: 15px 20px 15px;
  }
  .error-box {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    img {
      margin-bottom: 25px;
    }
  }
  .sucess-mail {
    h4 {
      font-size: 22px;
    }
  }
  .mobile-user-menu {
    > a {
      color: $color_17;
    }
  }
  .morning-img {
    img {
      position: relative;
      width: 100%;
    }
  }
  .good-morning-blk {
    &:before {
      display: none;
    }
    padding: 20px;
  }
  .morning-user {
    padding: 0;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .page-header {
    .breadcrumb {
      font-size: 14px;
    }
  }
  .dash-content {
    h4 {
      font-size: 14px;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 15px;
    }
  }
  .patient-visit {
    flex-wrap: wrap;
  }
  .chat-user-total {
    margin: 10px 0px;
  }
  .wallet-widget {
    .circle-bar {
      > div {
        canvas {
          width: 100px !important;
          height: 100px !important;
        }
      }
    }
  }
  .steps-history {
    li {
      font-size: 12px;
    }
  }
  .doctor-date-list {
    li {
      font-size: 12px;
      span {
        padding-left: 5px;
      }
    }
  }
  .border-right-bg {
    &:before {
      display: none;
    }
    margin-bottom: 10px;
  }
  .active-doctor {
    display: block;
  }
  .weight-blk {
    margin-bottom: 5px;
  }
  .treat-box {
    display: block;
  }
  .user-imgs-blk {
    margin-bottom: 10px;
    img {
      margin-right: 8px;
    }
  }
  .profile-user-img {
    img {
      width: 90px;
    }
  }
  .profile-edit-icon {
    img {
      width: 25px;
    }
  }
  .doctor-follows {
    h5 {
      font-size: 12px;
    }
    h4 {
      font-size: 16px;
    }
  }
  .follow-group {
    padding: 0px 15px;
  }
  .follow-btn-group {
    .btn-info {
      min-width: 115px;
      font-size: 14px;
      padding: 6px 15px;
    }
    margin: 15px 0px;
  }
  .names-profiles {
    h4 {
      font-size: 16px;
    }
  }
  .tab-content-set {
    li {
      a {
        font-size: 16px;
      }
      margin-bottom: 15px;
    }
    ul {
      li {
        a.active {
          &::after {
            display: none;
          }
        }
        a {
          &:hover {
            &::after {
              display: none;
            }
          }
        }
      }
    }
    padding: 0;
  }
  .detail-personal {
    margin-bottom: 15px;
  }
  .doctor-submit {
    .btn-primary {
      min-width: 125px;
      font-size: 12px;
      border-radius: 12px;
      padding: 7px 15px;
      margin-bottom: 8px;
      // margin-right: 0px !important;
    }
  }
  .invoice-counts {
    h4 {
      display: inline-block;
    }
  }
  .activity-list {
    &::before {
      display: none;
    }
  }
  .timeline-group-blk {
    display: block;
  }
  .comman-activitys {
    padding: 12px;
    margin-top: 5px;
  }
  .activity-sub-list {
    li {
      margin-bottom: 10px;
    }
  }
  .latest-posts-act {
    .post-thumb {
      float: inherit;
      margin-bottom: 15px;
    }
    .post-info {
      margin-left: 0;
    }
  }
  .blog-grp-blk {
    flex-wrap: wrap;
    span {
      margin-left: auto;
    }
  }
  .blog-img-blk {
    margin-bottom: 5px;
  }
  .grid-blog {
    .blog-title {
      a {
        font-size: 15px;
      }
    }
  }
  .chat-search-group {
    display: block;
  }
  .chat-search-list {
    ul {
      margin-top: 10px;
      li {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .inputgroups {
    input[type="text"] {
      padding-right: 125px;
    }
  }
  .settings-btn.upload-files-avator {
    .upload {
      width: 120px;
      font-size: 12px;
    }
  }
  .mailview-header {
    display: block;
  }
  .send-user.send-user-name {
    h4 {
      display: block;
      span {
        display: inline-block;
        margin: 0;
      }
    }
  }
  .sender-info {
    margin-bottom: 10px;
  }
  .mail-attach {
    .msg-sub-list {
      li {
        margin-bottom: 10px;
      }
    }
  }
  .forward-send {
    .btn-primary {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .chat-user-group.clinic-user {
    display: block !important;
  }
  .call-list-add {
    padding: 0;
  }
  .meeting {
    .join-video {
      height: 116px;
    }
  }
  .join-video.single-user {
    width: 100%;
    height: auto;
  }
  .meet-icons {
    .meet-items {
      .meet-item {
        > a {
          width: 35px;
          height: 35px;
        }
        margin-right: 5px;
      }
    }
  }
  .end-call-chat {
    .btn-primary {
      min-width: 90px;
      margin: 0px 5px;
      padding: 6px 13px;
      font-size: 14px;
    }
  }
  .action-items {
    .action-item {
      margin-right: 5px;
    }
  }
  .card {
    width: 100%;
  }
  .patient-structure {
    h3 {
      font-size: 32px;
    }
  }
  .chart-user-icon img {
    display: none;
  }
  .doctor-table-blk h3 {
    margin-bottom: 10px;
  }
  .download-grp {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 575.98px) {
  .app-listing {
    li {
      width: 100%;
    }
  }
  .invoices-tabs {
    ul {
      li {
        margin-right: 0;
        display: block;
        margin-bottom: 14px;
        a {
          padding-bottom: 0;
        }
      }
    }
  }
  .invoices-settings-btn {
    justify-content: flex-start;
  }
  .invoices-create-btn {
    .btn {
      font-size: 14px;
      padding: 10px;
      min-width: auto;
    }
  }
  .invoice-info-card {
    .invoice-item-one {
      .invoice-info {
        text-align: left;
      }
    }
  }
  .invoice-item {
    .invoice-details {
      text-align: left;
      float: left;
    }
  }
  .invoice-info.invoice-info2 {
    text-align: left;
  }
  .invoice-total-footer {
    h4 {
      font-size: 14px;
    }
  }
  .nav-tabs.menu-tabs {
    .nav-link {
      padding: 10px;
    }
  }
  ul.nav.nav-tabs.menu-tabs {
    padding: 10px;
  }
  .invoice-setting-btn {
    .btn {
      min-width: auto;
      padding: 10px;
    }
  }
  .contact-cat {
    padding-right: 70px;
  }
  .user-menu {
    display: none;
  }
  .mobile-user-menu {
    display: block;
  }
  .search-box {
    display: none;
  }
  .filter-row {
    margin-bottom: 15px;
  }
  .page-wrapper {
    > .content {
      padding: 15px;
    }
  }
  .profile-view {
    .profile-basic {
      margin-left: 0;
    }
    .profile-img-wrap {
      margin: 0 auto 10px;
      height: 100px;
      width: 100px;
    }
    .profile-img {
      height: 100px;
      width: 100px;
      .avatar {
        font-size: 24px;
        height: 100px;
        line-height: 100px;
        margin: 0;
        width: 100px;
      }
    }
  }
  .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }
  .profile-info-left {
    text-align: center;
  }
  .profile-basic {
    margin-left: 0;
    margin-top: 15px;
  }
  .page-title {
    font-size: 18px;
  }

  .upload-text {
    font-size: 18px;
  }
  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }
  .end-call {
    margin-top: 10px;
    position: inherit;
  }
  .chat-right {
    .chat-content {
      max-width: 90%;
    }
    .chat-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .chat-left {
    .chat-content {
      max-width: 90%;
    }
    .chat-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .chat-avatar {
    .avatar {
      height: 20px;
      line-height: 20px;
      width: 20px;
    }
  }
  .account-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .content {
    padding: 0 50px;
  }
  .custom-menu.navbar-nav {
    > li {
      > a {
        margin-left: 10px;
      }
    }
  }
  .error-box {
    h1 {
      font-size: 50px;
    }
  }
  .submit-btn {
    min-width: 150px;
  }
  .ui-kit-wrapper {
    button {
      margin-bottom: 5px;
    }
  }
  .pagination {
    > li {
      > a {
        padding: 0.5rem !important;
      }
      > span {
        padding: 0.5rem !important;
      }
    }
  }
  .invoice-item-box {
    float: left;
  }
}
@media only screen and (max-width: 479px) {
  .compose-btn {
    button {
      padding: 0.365rem 0.5rem;
    }
  }
  .attachments {
    li {
      float: none;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
}



.wizard .nav-tabs {
  position: relative;
  margin-top: 20px;
  border: 0;
margin-bottom: 30px;
}
.wizard .nav-item {
  position: relative;
}
.wizard .nav-item .nav-link {
  width: 56px;
  height: 56px;
  content: "";
  position: absolute;
  background: #fff;
  left: 50%;
  color: #5156be;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid rgba(81, 86, 190, 0.2);
}
.wizard .nav-tabs li:first-child::after {
display: none;
}
.wizard .nav-item .nav-link.active {
  background: #e9e9ef;
  color: #5156be;
  border: 1px solid rgba(81, 86, 190, 0.2);
}
.wizard .nav-tabs li:after {
  content: "";
  width: 75%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  background: #e9e9ef;
  height: 2px;
  left: -30%;
  top: 38%;
  z-index: 1;
}
.wizard .nav-link {
  width: 50px;
  height: 50px;
  text-align: center;
}

.wizard .nav-item {
  flex: 1;
}

.input-block {
  margin-bottom: 15px;
}

.mat-mdc-select-placeholder .mat-mdc-select-min-line .ng-tns-c3393473648-0 .ng-star-inserted {
  font-size: 13px;
}
[dir="rtl"] {
  text-align: right;
}

[dir="ltr"] {
  text-align: left;
}